import { Box, SxProps, Typography } from '@mui/material';
import React from 'react';
import BenchmarkIcon from '../../components/Icons/BenchmarkIcon';
import { palette } from '../../theme/palette';
import { toReadableFormat } from '../../utils/dateFormat';
import { boxStyles, capIconStyles, StyledFlexBox, StyledIconContainer, StyledImage, StyledImageOverlayBox, StyledTaskButton } from './StudentDashboard.styled';

interface Props {
  assignmentId: string;
  resourceUrl: string;
  teacherUserId: string;
  submissionId: string;
  assignmentTitle: string;
  closeAt: string;
  teacherIDColorMap: Record<string, string>;
  handleRedirection: (id: string, submissionId?: string | undefined, isBenchmark?: boolean) => () => Promise<void>;
  isBenchmark?: boolean;
  teachers?: string,
  thumbnailUrl: string,
}

const DashboardAssignmentBenchmarkTile = ({
  assignmentId,
  resourceUrl,
  teacherUserId,
  submissionId,
  assignmentTitle,
  closeAt,
  teacherIDColorMap,
  handleRedirection,
  isBenchmark = false,
  teachers,
  thumbnailUrl = '',
}: Props) => {
  return (
    <Box
      sx={{ ...boxStyles.assignmentContainer, p: '0px', mt: 4 }}
      key={assignmentId}
    >
      <Box
        position="relative"
        width="100%"
        height="75%"
      >
        <StyledImage
          src={thumbnailUrl ? thumbnailUrl : resourceUrl}
          alt='task'
          sx={{  borderRadius: '12px 12px 0 0' }}
        />
        <StyledImageOverlayBox
          style={{ backgroundColor: teacherIDColorMap[teacherUserId || 'new'] }}
        >
          {isBenchmark && <StyledIconContainer sx={{  ...capIconStyles.iconWrapper, ...capIconStyles.iconPosition } as SxProps}>
            <BenchmarkIcon pathfill={palette.customWhite.main} sx={{ ...capIconStyles.iconDimensions }}/>
          </StyledIconContainer>}
          <StyledTaskButton
            variant="contained"
            color="primary"
            onClick={
              handleRedirection(
                assignmentId,
                submissionId ?? undefined,
                isBenchmark,
              )
            }
          >
            {submissionId ? 'Resume' : 'Start'}
          </StyledTaskButton>
        </StyledImageOverlayBox>
      </Box>
      <StyledFlexBox>
        <Typography variant='sContentHeader'>{assignmentTitle}</Typography>
        {!isBenchmark && <Typography variant='secondaryLabel' sx={{ color: palette.fontColors.fontBlack }}>
          {`${teachers}`}
        </Typography>}
        <Typography variant='secondaryLabel' sx={{ color: palette.fontColors.fontBlack }}>
          {closeAt ? `Due by ${toReadableFormat(closeAt)}` : ''}
        </Typography>
      </StyledFlexBox>
    </Box>
  );
};

export default DashboardAssignmentBenchmarkTile;
