import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from '../../components/Layout';
import Performance from './Performance';
import LearningGoals from './LearningGoals';
import DistrictWideComparisons from './DistrictWideComparisons';
import RoutingPaths from '../RoutingPath';
import { useAdminAnalytics } from './admin-analytics-hook';

const AdminAnalytics = () => {
  const {
    isDistrictAdmin, myDistrictDataLoading, myDistrictData,
    isSchoolAdmin, mySchoolDataLoading, mySchoolData,
    academicSessionLoading, academicSession,
  } = useAdminAnalytics();
  const additionalProps = {
    isDistrictAdmin, myDistrictDataLoading, myDistrictData,
    isSchoolAdmin, mySchoolDataLoading, mySchoolData,
    academicSessionLoading, academicSession,
  };
  return (
    <>
      {!academicSessionLoading && !!academicSession && (<Layout>
        <Switch>
          <Route
            path={RoutingPaths.Performance}
            // component={Performance}
            render={(routeProps) => <Performance {...routeProps} {...additionalProps} />}
            exact
          />
          <Route
            path={RoutingPaths.LearningGoals}
            // component={LearningGoals}
            render={(routeProps) => <LearningGoals {...routeProps} {...additionalProps} />}
            exact
          />
          <Route
            path={[
              RoutingPaths.DistrictWideComparison,
              RoutingPaths.TabKeyDistrictWideComparison,
            ]}
            component={DistrictWideComparisons}
            exact
          />
          <Redirect
            from={RoutingPaths.AdminAnalytics}
            to={RoutingPaths.Performance}
          />
        </Switch>
      </Layout>)}
    </>
  );
};

export default AdminAnalytics;
