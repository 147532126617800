import React from 'react';
import AdminDashboardPerformanceGraph from '../../../../components/AdminGraphs/AdminDashboardPerformanceGraph';
import useOverallPerformanceInfo from './overall-performance-widget-hook';
import { AdminDashboards } from '../../../../variables/constant';

const OverallPerformanceWidget = () => {

  const {
    overallPerformanceDataLoading, overallPerformanceData, title,
    sessionStartDate,
    sessionEndDate,
    currStartDate,
    currEndDate,
  } = useOverallPerformanceInfo();

  const overallPerformanceDataGraphData: any = overallPerformanceData?.historical_score?.map((performance: any) => {
    return {
      date: performance?.date_timestamp,
      avgScore: performance?.average_score,
      totalPtScored: performance?.scored_pt_count,
      uniqueStudentCount: performance?.unique_student_count,
    };
  });

  return (
    <>
      <AdminDashboardPerformanceGraph
        loading={overallPerformanceDataLoading || !overallPerformanceData}
        sessionStartDate={sessionStartDate}
        sessionEndDate={sessionEndDate}
        currStartDate={currStartDate}
        currEndDate={currEndDate}
        title = {title}
        alignTitle
        tooltipTitle={AdminDashboards.performance.overallPerformance.tooltipText}
        currentScore = {`${overallPerformanceData?.current_day_average_score || '-'}`}
        data={overallPerformanceDataGraphData}
        enableTooltip
      />
    </>
  );
};

export default OverallPerformanceWidget;
