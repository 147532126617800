import { Box, styled, TableCell, TableContainer, TableSortLabel } from '@mui/material';
import { palette } from '../../theme/palette';

export const svgStyles = {
  icon: {
    '&.MuiSvgIcon-root': {
      marginLeft: '8px',
      width: '20px',
      height: '20px',
    },
  },
};

export const StyledFlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(1),
}));

export const StyledFlexColBoxContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const StyledTableContainer = styled(TableContainer)({
  height: 'calc(100vh - 300px)',
});

export const StyledContainerRoot = styled(StyledTableContainer)(({ theme }) => ({
  '&.MuiTableContainer-root': {
    borderColor: theme.palette.primary.main,
  },
}));

export const StyledContainerRoot2 = styled(TableContainer)(({ theme }) => ({
  '&.MuiTableContainer-root': {
    borderColor: theme.palette.primary.main,
  },
}));


export const StyledTableSort = styled(TableSortLabel)(({ theme }) => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  '&.Mui-active': {
    color: 'inherit !important',
  },
  '&.MuiTableSortLabel-root': {
    color: theme.palette.customWhite.main,
    '&:hover': {
      color: theme.palette.customWhite.main,
    },
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.customWhite.main,
    '& path': {
      fill: theme.palette.customWhite.main,
    },
  },
}));

export const StyledTableSort2 = styled(TableSortLabel)(({ theme }) => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  '&.Mui-active': {
    color: 'inherit !important',
  },
  '&.MuiTableSortLabel-root': {
    color: theme.palette.customBlack.dimmedBlack,
    '&:hover': {
      color: theme.palette.customBlack.dimmedBlack,
    },
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.customBlack.dimmedBlack,
    '& path': {
      fill: theme.palette.customBlack.dimmedBlack,
    },
  },
}));

export const tableWithNoDataStyles = {
  noData: {
    height: '100%',
    '& tbody tr:hover': {
      backgroundColor: `${palette.transparent.main} !important`,
      cursor: 'initial !important',
    },
  },
};

export const StyledTableDataColumn = styled(TableCell)(() => ({
  fontSize: '16px',
  fontWeight: 'normal',
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-root': {
    backgroundColor: theme.palette.primary.main,
    border: 0,
    color: theme.palette.customWhite.main,
    '& .MuiTableSortLabel-root': {
      color: theme.palette.customWhite.main,
    },
    maxHeight: '60px',
  },
}));

export const boldFontWeight = {
  boldFont: {
    fontWeight: 'bold',
  },
};

export const StyledTableSecondary = styled(StyledTableContainer)(() => ({
  height: '200px',
  '&.MuiTableContainer-root': {
    borderColor: palette.customBlue.primaryBlue,
  },
}));

export const StyledTableSorted = styled(TableSortLabel)(({ theme }) => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  '&.Mui-active': {
    color: palette.fontColors.fontBlack,
    '&.MuiTableSortLabel-icon': {
      opacity: 0,
      color: palette.customWhite.main,
    },
    '&:hover': {
      '& .MuiTableSortLabel-icon': {
        opacity: 1,
        color: palette.fontColors.fontBlack,
      },
    },
  },
}));

export const StyledTableCellFlyover = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-root': {
    cursor: 'default',
    fontSize: '18px',
    padding: theme.spacing(2, 3),
    color: theme.palette.customWhite.main,
    borderBottom: `2px solid ${palette.customBackground.disabled} !important`,
  },
}));

export const StyledTableContainerFlyover = styled(TableContainer)({
  height: '100%',
  borderRadius: 0,
  border: 'none',
  overflow: 'hidden',
});
