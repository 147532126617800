import { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQueryParam } from 'use-query-params';
import { openSnackbar } from '../../../components/Notifier';
import getErrorMessage from '../../../utils/getErrorMessage';
import { NotifierType } from '../../../variables/types';
import SchoolsIcon from '../../../components/Icons/SchoolsIcon';
import {
  useSchoolEditDetailsQuery,
  useSchoolFormMutation,
  usePredefinedGradesListQuery,
} from '../../../generated/graphql';

type SchoolFormData = {
  id: string
  school_id: string
  name: string
  district_id: string
  grades:[string]
  state_id: string
};

interface RouteProp {
  id: string
}

const useSchoolForm = () => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    reset,
    setValue,
    clearErrors,
  } = useForm<SchoolFormData>();
  const [district] = useQueryParam('districtId');
  const [state] = useQueryParam('stateId');

  const [schoolForm, { loading }] = useSchoolFormMutation();
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setChecked(event.target.checked);
  };

  const onDistrictChange = (stateId?: string | null) => {
    if (stateId) {
      setValue('state_id', stateId);
      clearErrors('state_id');
    }
  };

  const onStateChange = () => {
    setValue('district_id', null);
  };

  const { data: preDefinedGrades, loading: preDefinedGradesLoading } = usePredefinedGradesListQuery({
    fetchPolicy: 'network-only',
  });

  const { id } = useParams<RouteProp>();
  if (id) {
    register('id', { required: true });
  }

  const { data: schoolDetails, loading: schoolDetailsLoading } = useSchoolEditDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: id!,
    },
    skip: !id,
  });

  const existingGrades = schoolDetails?.schoolDetails?.school_predefined_grade_mappings?.map(
    (schoolGrade) => schoolGrade?.predefined_grades?.id,
  );

  useEffect(() => {
    if (state) {
      setValue('state_id', state);
    }
  }, [setValue, state]);

  useEffect(() => {
    if (district) {
      setValue('district_id', district);
    }
  }, [setValue, district]);

  useEffect(() => {
    if (schoolDetails) {
      const grades = schoolDetails.schoolDetails?.school_predefined_grade_mappings?.map(
        (schoolGrade) => schoolGrade?.predefined_grades?.id,
      );
      reset({
        id: schoolDetails.schoolDetails.id,
        name: schoolDetails.schoolDetails.name,
        school_id: schoolDetails.schoolDetails.school_id,
        district_id: schoolDetails.schoolDetails.district_id,
        state_id: schoolDetails.schoolDetails.district?.state_id || undefined,
        grades: grades as string[],
      });
    }
  }, [id, reset, schoolDetails]);

  if (!id && !getValues('grades')) {
    setValue('grades', preDefinedGrades?.allPredefinedGrades?.map((grade) => grade.id));
  }

  const sortedPredefinedGrades = useMemo(() => [...(preDefinedGrades?.allPredefinedGrades ?? [])].sort(
    (a, b) => {
      if (!a || !a.position) {
        return 1;
      }
      if (!b || !b.position) {
        return -1;
      }
      if (a?.position > b?.position) {
        return 1;
      }
      if (a?.position < b?.position) {
        return -1;
      }
      return 0;
    },
  ), [preDefinedGrades]);

  const create = async (school: SchoolFormData) => {
    const schoolName = school.name;
    const successMessage = `${schoolName} school was ${school.id ? 'updated' : 'added'}!`;
    try {
      const response = await schoolForm({
        variables: {
          input: {
            id: school.id,
            name: school.name.trim(),
            school_id: school.school_id.trim(),
            district_id: school.district_id,
            grades: school.grades || [],
          },
        },
      });
      const schoolDetailsPath = `/schools/${response?.data?.upsertSchool?.trim()}`;
      if (checked) {
        reset({
          id: '',
          school_id: '',
          name: '',
          district_id: school.district_id,
          grades: preDefinedGrades?.allPredefinedGrades?.map((grade) => grade.id),
          state_id: school.state_id,
        });
      } else {
        history.push(schoolDetailsPath);
      }
      openSnackbar({
        message: successMessage,
        customIcon: SchoolsIcon,
        actionButtonText: 'view',
        onActionButtonClick: () => { history.push(schoolDetailsPath); },
      }, NotifierType.Success);
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  const selectedStateId = watch('state_id');

  return {
    loading: loading || preDefinedGradesLoading || schoolDetailsLoading,
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    createSchool: create,
    checked,
    handleChange,
    preDefinedGrades,
    onDistrictChange,
    onStateChange,
    existingGrades,
    selectedStateId,
    sortedPredefinedGrades,
    source : schoolDetails?.schoolDetails?.district?.source,
    schoolID: id,
  };
};

export default useSchoolForm;
