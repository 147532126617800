import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import SubmissionTable from '../Assignments/Assignment/SubmissionTable';
import AppFilter from '../../components/AppFilter';
import ListMenu from '../../components/AppFilter/ListMenu';
import TablePagination from '../../components/TablePagination';
import useSubmissions from './submissions-hook';
import { PAGE_SIZE } from '../../variables/constant';
import { SubmissionFilter } from '../../variables/types';
import { StyledContainerBox, StyledContentContainerBox, StyledTableContainer } from './TeacherDashboard.styled';
import useSubmissionQueueHook from '../ScoreTask/submission-queue-hook';
import SectionsFilter from '../../components/SectionsFilter';
import DebounceSearchBar from '../../components/DebounceSearchBar';
import TeacherSchoolFilter from '../../components/SchoolFilterTeacher';
import TypeformEmbed from '../../components/TypeformEmbed';
import { useImitationProvider } from '../../core/imitationContext';

const submissionOptions = [{
  label: 'All Submissions',
  value: SubmissionFilter.AllSubmissions,
},
{
  label: 'Graded',
  value: SubmissionFilter.Scored,
},
{
  label: 'Not Yet Graded',
  value: SubmissionFilter.NotScored,
}];

const TeachersDashboard = () => {
  const {
    loading,
    pagination,
    search,
    onSearch,
    filter,
    onFilter,
    submissions,
    totalCount,
    onSortChange,
    sort,
    order,
    submissionQueueData,
    section,
    onSectionChange,
    school,
    onSchoolChange,
    benchmarkV2TypeformData,
    onTypeformReady,
    onTypeformSubmit,
  } = useSubmissions();

  const { getImitationUserType } = useImitationProvider();
  const isNotImitated = !getImitationUserType();

  const {
    initializeSubmissionQueue,
  } = useSubmissionQueueHook(false);

  const createQueue = (id: string) => {
    initializeSubmissionQueue(submissionQueueData, id);
  };

  const handleChange = (_: any, value: React.SetStateAction<number>) => {
    if (typeof value === 'number') {
      pagination.setPage(value);
    }
  };

  return (
    <StyledContainerBox>
      <StyledContentContainerBox>
        <Box>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item >
              <Box mb={1}>
                <Typography variant="pageTitle">Grade</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} lg={6} spacing={2}>
              <AppFilter>
                <Grid item xs={10} lg={10} mr={2}>
                  <ListMenu
                    menuTitle="All Submissions"
                    hideMenuTitle
                    selectedItem={filter}
                    onChange={onFilter}
                    options={submissionOptions}
                    keyAttribute="value"
                    labelAttribute="label"
                  />
                </Grid>
                <Grid item xs={10} lg={10} mr={2}>
                  <TeacherSchoolFilter
                    value={school}
                    onChange={onSchoolChange}
                    placeholder="All Schools"
                    idSelectOnly
                  />
                </Grid>
                <Grid item xs={10} lg={10}>
                  <SectionsFilter
                    value={section}
                    onChange={onSectionChange}
                    idSelectOnly
                    placeholder="All Classes"
                    schoolId={school as string | undefined}
                  />
                </Grid>
              </AppFilter>
            </Grid>
          </Grid>
          <Grid container xs={6} lg={5}>
            <DebounceSearchBar
              handleChange={onSearch}
              searchTerm={search}
            />
          </Grid>
          <StyledTableContainer>
            <SubmissionTable
              stickyHeader
              loading={loading}
              submissions={submissions}
              sort={sort}
              order={order}
              onSortChange={onSortChange}
              createQueue={createQueue}
            />
          </StyledTableContainer>
        </Box>
        {totalCount > PAGE_SIZE && (
          <Box
            display="flex"
            justifyContent="flex-end"
            paddingTop="20px"
          >
            <TablePagination
              count={Math.ceil(totalCount / PAGE_SIZE) || 0}
              page={pagination.page}
              onChange={handleChange}
              defaultPage={1}
            />
          </Box>
        )}
      </StyledContentContainerBox>
      {
        isNotImitated && benchmarkV2TypeformData?.show_typeform && <TypeformEmbed
          formId={benchmarkV2TypeformData?.typeform_form_id}
          typeformLayout='popup'
          onCloseHandler={onTypeformReady}
          onFormSubmittedHandler={onTypeformSubmit}
          hiddenButton={true}
          delay={500}
          buttonText='Feedback Form'/>
      }
    </StyledContainerBox>
  );
};

export default TeachersDashboard;
