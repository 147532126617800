import { jsPDF } from 'jspdf';

export const colors = {
  bgGrey: '#D1D5DB',
  bgRed: '#F86464',
  bgYellow: '#FBDA67',
  bgGreen: '#2BCC57',
  bgBlue: '#0090F9',
  bgPurple: '#2A2A8B',
  fntWhite: '#FFFFFF',
  fntBlack: '#333333',
};

export interface PdfProps {
  pdfDoc: jsPDF,
  pageWidth: number,
  pageHeight: number,
  pdfMargin: number,
  pdfPadding: number,
  posX: number,
  posY: number,
}

export const pdfNewPage = (pdfDoc: jsPDF, pageHeight: number, pdfMargin: number, pdfPadding: number, posX: number, posY: number, elHeight: number) => {
  if (posY + elHeight > pageHeight) {
    pdfDoc.addPage();
    posX = pdfMargin;
    posY = pdfPadding + 5;
    pdfDoc.line(posX, posY, pdfDoc.internal.pageSize.width - pdfMargin, posY);
  }
  return [posX, posY + 5];
};

export const adjustWidgetElementHeightWidth = (widgetElement: HTMLElement, pageWidth: number, pdfMargin: number) => {
  let elHeight = widgetElement.offsetHeight;
  let elWidth = widgetElement.offsetWidth;

  if (elWidth > pageWidth) {
    let ratio = pageWidth / elWidth;
    elHeight = elHeight * ratio - pdfMargin * 2;
    elWidth = elWidth * ratio - pdfMargin * 2;
  }
  return [elHeight, elWidth];
};

export const getPdfTextWidth = (text: string, pdf: jsPDF, fontSize: number) => {
  return pdf.getStringUnitWidth(`${text}` as string) * fontSize / pdf.internal.scaleFactor;
};

export const drawAutotableRowBorders = (data: any, pdf: jsPDF) => {
  const { table, row, column, cell } = data;

  // Draw top border for all cells in the row except the first one
  if (row.index !== 0) {
    pdf.setDrawColor(0);
    pdf.setLineWidth(0.1);
    pdf.line(cell.x, cell.y, cell.x + cell.width, cell.y);
  }

  // Draw bottom border for all cells in the row
  pdf.setDrawColor(0);
  pdf.setLineWidth(0.1);
  pdf.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height);

  // Draw left border for the first cell in the row
  if (column.index === 0) {
    pdf.setDrawColor(0);
    pdf.setLineWidth(0.1);
    pdf.line(cell.x, cell.y, cell.x, cell.y + cell.height);
  }

  // Draw right border for the last cell in the row
  if (column.index === table.columns.length - 1) {
    pdf.setDrawColor(0);
    pdf.setLineWidth(0.1);
    pdf.line(cell.x + cell.width, cell.y, cell.x + cell.width, cell.y + cell.height);
  }
};

const getDistrictWideScoreChipStyle = (score: number) => {
  let bgColor = colors.bgGrey;
  let fntColor = colors.fntWhite;
  if (score === 0) {
    bgColor = colors.bgGrey; fntColor = colors.fntBlack;
  } else if (0 < score && score <= 1.4) bgColor = colors.bgRed;
  else if (1.5 <= score && score <= 2.4) {
    bgColor = colors.bgYellow; fntColor = colors.fntBlack;
  } else if (2.5 <= score && score <= 3.4) bgColor = colors.bgGreen;
  else if (3.5 <= score && score <= 4.4) bgColor = colors.bgBlue;
  else if (4.5 <= score && score <= 5) bgColor = colors.bgPurple;

  return { textColor: fntColor, fillColor: bgColor, text: score.toString(), fontSize: 8 };
};

export const drawAutotableCellChipWithText = (data: any, pdf: jsPDF, cols: number[]) => {
  const { row, column, cell } = data;

  // Skip the header row
  if (row.section === 'head' && row.index === 0) return;

  // Check if the current cell is in the specified column
  if (cols.includes(column.index)) {
    const badgeValue = cell.raw;

    // Customize badge styles
    const badgeStyles = getDistrictWideScoreChipStyle(badgeValue);

    // Draw the badge
    pdf.setFillColor(badgeStyles.fillColor);
    const badgeWidth = getPdfTextWidth('xxxxxx', pdf, badgeStyles.fontSize);
    // pdf.getStringUnitWidth('xxxxxx' as string) * badgeStyles.fontSize / pdf.internal.scaleFactor;
    const badgeX = cell.x + (cell.width - badgeWidth) / 2;
    const badgeY = cell.y + 1;
    pdf.roundedRect(badgeX, badgeY, badgeWidth, cell.height - 4, 4, 4, 'F');

    // Draw the badge text
    pdf.setTextColor(badgeStyles.textColor);
    pdf.setFontSize(badgeStyles.fontSize);
    const textWidth = getPdfTextWidth(`${badgeValue}`, pdf, badgeStyles.fontSize);
    // pdf.getStringUnitWidth(`${badgeValue}` as string) * badgeStyles.fontSize / pdf.internal.scaleFactor;
    const textX = cell.x + (cell.width - textWidth) / 2;
    const textY = cell.y + cell.height / 2 + 1;
    pdf.text(`${badgeValue}` as string, textX, textY);
  }
};

interface DrawChipWithTextProps {
  pdf: jsPDF,
  text: string,
  fontSize: number,
  x: number,
  y: number,
  rx: number,
  ry: number,
  textWidth: number,
  height: number,
  backgroundColor: string,
  textColor: string,
}

export const drawChipWithText = ({
  pdf, text, fontSize, x, y, rx, ry, textWidth, height, backgroundColor, textColor,
}: DrawChipWithTextProps) => {
  // Draw the rectangular chip
  pdf.setFillColor(backgroundColor);
  pdf.roundedRect(x, y, textWidth + 2 * rx, height, rx, ry, 'F');

  // Add text inside the chip
  pdf.setTextColor(textColor);
  pdf.setFontSize(fontSize);
  pdf.text(text, x + rx + (textWidth / 2), y + height / 2, { align: 'center', baseline: 'middle' });
};