import React from 'react';
import { Box, Button } from '@mui/material';
import { useRouteMatch, useHistory } from 'react-router-dom';
import {
  StudentsIcon,
} from '../Icons';
import { styled } from '@mui/material';
import ToolBoxIcon from '../Icons/ToolBoxIcon';
import LightningBolt from '../Icons/LightningBolt';
import CheckedCircleInvertedFill from '../Icons/CheckedCircleInvertedFill';
import SolidPhotograph from '../Icons/SolidPhotograph';
import Loader from '../Loader/loader';
import useSidebarHook from './sidebar-hook';
import { palette  } from '../../theme/palette';
import TreeViewNavigation from './TreeViewNavigation';
import BenchmarkIcon from '../Icons/BenchmarkIcon';
import TreeMapSelectedIcon from '../Icons/TreeMapSelectedIcon';
import NavigationChip from '../NavigationChip';
import RoutingPaths from '../../pages/RoutingPath';

const navStyles = {
  pill: {
    color: palette.fontColors.fontBlack,
    border: 'none',
    padding: '0px 10px',
    borderRadius: '16px',
    fontSize: 'small',
    backgroundColor: palette.customBackground.dragBackground,
    marginLeft: '55px',
    minWidth: '30px',
    fontWeight: 'bold',
  },
};
export const StyledNavigationButton = styled(Button)({
  borderWidth: 0,
  borderRadius: 0,
  padding: '6px 8px',
  marginTop: 5,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    borderWidth: 0,
    borderRadius: 0,
  },
  '&::after': {
    display: 'none',
  },
});

export const StyledNavigationButtonBox = styled(Box)<{ active?: string }>(({ theme, active }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: 8,
  border: '2px solid transparent',
  borderRadius: 12,
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 600,
  textTransform: 'capitalize',
  color:  active === 'true' ? theme.palette.customWhite.main : theme.palette.customBlack.lightBlack,
  boxSizing: active === 'true' ? 'border-box' : undefined,
  backgroundColor: active === 'true' ? theme.palette.primary.main : 'inherit',
}));


const TeacherOptions = () => {
  const history = useHistory();

  const {
    loading,
    notSubmittedSubmissionCount,
    refetch,
    sidenavBenchmarkGradeTileCount,
    teacherBenchmarksDataRefetch,
  } = useSidebarHook();

  const navigateTo = (path: string) => () => {
    refetch();
    teacherBenchmarksDataRefetch();
    history.push(path);
  };

  const isBenchmarkList = useRouteMatch({ path: [RoutingPaths.TeacherBenchMarks, RoutingPaths.ScoreTaskBenchmarks, `${RoutingPaths.ScoreTaskBenchmarks}/:section`,], exact: false });
  const isBenchmarkGrade = useRouteMatch({ path: [RoutingPaths.TeacherGradeBenchMarks, RoutingPaths.ScoreTaskBenchmarks, `${RoutingPaths.ScoreTaskBenchmarks}/:section`,], exact: false });

  const benchmarkSidebar = {
    mainCategory: {
      indexId: '1',
      label: 'Benchmarks',
      linkToRedirect: RoutingPaths.TeacherBenchMarks,
      routeMatches: isBenchmarkList,
      icon: BenchmarkIcon,
    },
    subItems: [
      {
        indexId: '1.a',
        label: 'Grade',
        linkToRedirect: RoutingPaths.TeacherGradeBenchMarks,
        routeMatches: isBenchmarkGrade,
        icon: TreeMapSelectedIcon,
        counterChips: <NavigationChip dataCount={sidenavBenchmarkGradeTileCount} />,
      },
    ],
  };
  return (
    <Box width="100%">
      <Loader open={loading} />
      <StyledNavigationButton
        fullWidth
        onClick={navigateTo(RoutingPaths.AnalyticsOverview)}
      >
        <StyledNavigationButtonBox
          active={(!!useRouteMatch({ path: RoutingPaths.Analytics, exact: false }))?.toString()}
        >
          <LightningBolt
            sx={{ mr: '5px' }}
            fill={palette.fontColors.fontBlack}
          />
          Take Action
        </StyledNavigationButtonBox>
      </StyledNavigationButton>
      <StyledNavigationButton
        fullWidth
        onClick={navigateTo(RoutingPaths.Grade)}
      >
        <StyledNavigationButtonBox
          active={(!!useRouteMatch({ path: [RoutingPaths.Grade, `${RoutingPaths.ScoreTaskBenchmarks}/:section`], exact: false }))?.toString()}
        >
          <CheckedCircleInvertedFill
            sx={{ mr: '5px' }}
            fill={palette.fontColors.fontBlack}
          />
          Grade
          <Box
            sx={{ ...navStyles.pill }}
          >
            {notSubmittedSubmissionCount}
          </Box>
        </StyledNavigationButtonBox>
      </StyledNavigationButton>
      <StyledNavigationButton
        fullWidth
        onClick={navigateTo(RoutingPaths.Assignments)}
      >
        <StyledNavigationButtonBox
          active={(!!useRouteMatch({ path: RoutingPaths.Assignments, exact: false }))?.toString()}
        >
          <SolidPhotograph
            sx={{ mr: '5px' }}
            fill={palette.fontColors.fontBlack}
          />
          Assign
        </StyledNavigationButtonBox>
      </StyledNavigationButton>
      <StyledNavigationButton
        fullWidth
      >
        <TreeViewNavigation
          mainCategoryItem={benchmarkSidebar.mainCategory}
          subCategoryItems={benchmarkSidebar.subItems}
        />
      </StyledNavigationButton>
      <StyledNavigationButton
        fullWidth
        onClick={navigateTo(RoutingPaths.Students)}
      >
        <StyledNavigationButtonBox
          active={(!!useRouteMatch({ path: [RoutingPaths.Students, RoutingPaths.Groups], exact: false }))?.toString()}
        >
          <StudentsIcon
            sx={{ mr: '5px' }}
          />
          Students
        </StyledNavigationButtonBox>
      </StyledNavigationButton>
      <StyledNavigationButton
        fullWidth
        onClick={navigateTo(RoutingPaths.ToolBox)}
      >
        <StyledNavigationButtonBox
          active={(!!useRouteMatch({ path: RoutingPaths.ToolBox, exact: false }))?.toString()}
        >
          <ToolBoxIcon
            sx={{ mr: '5px' }}
          />
          Toolbox
        </StyledNavigationButtonBox>
      </StyledNavigationButton>
    </Box>
  );
};

export default TeacherOptions;
