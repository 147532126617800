import { endOfDay, format, startOfDay } from 'date-fns';
import { READABLE_DATE_OPTIONS, READABLE_SINGLE_DATE_FORMAT, READABLE_MONTH_OPTIONS } from '../variables/constant';

/**
 * returns a date in UTC with the passed format.
 * @param {string} date
 * @param {object} dateFormat: READABLE_SHORT_DATE_OPTIONS returns 'Jun 18'
 * @param {string} locale
 * default output without optional params: 'Fri,Jun 18'
 */
export const toReadableFormat = (date: string, dateFormat?: object, locale?: string) => new Intl.DateTimeFormat(
  locale ?? 'en-US', dateFormat ?? READABLE_DATE_OPTIONS,
).format(new Date(date));

export const toReadableMonthFormat = (date: string, dateFormat?: object, locale?: string) => new Intl.DateTimeFormat(
  locale ?? 'en-US', dateFormat ?? READABLE_MONTH_OPTIONS,
).format(new Date(date));

export const toSingleReadableFormat = (date: string) => format(new Date(date), READABLE_SINGLE_DATE_FORMAT);

const pad = (n: number) => (n < 10 ? '0' : '') + n;
const padd = (n: number) => (n < 100 ? '0' : '') + pad(n);

export const toUTC = (date: Date): string => {
  if (!date) {
    return '';
  }
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())
  }T${pad(0)}:${pad(0)}:${
    pad(0)}.${padd(0)}Z`;
};

export const toUTCEOD = (date: Date): string => {
  if (!date) {
    return '';
  }
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())
  }T${pad(23)}:${pad(59)}:${
    pad(59)}.${padd(999)}Z`;
};

// parseISOLocal returns date object without timezone consideration
export const parseISOLocal = (stringDate: string, timeOfDay: String = 'SOD', defaultDate: Date = new Date()) => {
  if (stringDate) {
    const b = stringDate.split(/\D/);
    const date = new Date(Number(b[0]), Number(b[1]) - 1, Number(b[2]), Number(b[3]), Number(b[4]), Number(b[5]));
    switch (timeOfDay) {
      case 'SOD':
        return startOfDay(date);
      case 'EOD':
        return endOfDay(date);
      default:
        return date;
    }
  }
  return startOfDay(defaultDate);
};

// fromUTC returns exact point of time
export const fromUTC = (stringDate: string): Date => parseISOLocal(stringDate, '');

// fromUTCSOD returns start of day for passed date string
export const fromUTCSOD = (stringDate: string): Date => parseISOLocal(stringDate, 'SOD');

// fromUTCEOD returns end of day for passed date string
export const fromUTCEOD = (stringDate: string): Date => parseISOLocal(stringDate, 'EOD');

// from Date Object returns fromUTCEOD or fromUTCSOD
export const cleanDate = (dateToClean: any, EOD: boolean = false) => {
  if (dateToClean && !(dateToClean instanceof Date)) {
    if (EOD) return fromUTCEOD(dateToClean);
    return fromUTCSOD(dateToClean);
  }
  return dateToClean;
};
