import React, { useContext, useEffect } from 'react';
import {
  OrderBy,
  DistrictWideSchoolDetailsSortType,
  useDistrictWideSchoolDataQuery,
  useDistrictWideGradeDataQuery,
  DistrictWideGradeSortType,
} from '../../../../generated/graphql';
import usePagination from '../../../../utils/usePagination';
import {
  DistrictWideQueryInput,
  DistrictWideComparisonsContext,
} from '../district-wide-comparisons-hook';

interface Props {
  districtId?: string,
  schoolId?: string,
}

const useSchools = ({ districtId, schoolId }: Props) => {
  const {
    isSchoolAdmin, isDistrictAdmin, setWidgetLoading,
    schoolsDataCount, setSchoolsDataCount, setGradesDataCount,
    schoolsDataQueryInput, setSchoolsDataQueryInput,
  } = useContext(DistrictWideComparisonsContext);

  const updateSchoolsDataQueryInput = (data: Object) => {
    setSchoolsDataQueryInput?.({
      ...schoolsDataQueryInput,
      ...data,
    });
  };

  const pagination = usePagination('schoolsPage');

  var queryInput: DistrictWideQueryInput = schoolsDataQueryInput!;
  if (isSchoolAdmin && schoolId) queryInput.school_id = schoolId;
  if (isDistrictAdmin && districtId) queryInput.district_id = districtId;

  const updateQueryInput = (queryInputUpdate: DistrictWideQueryInput) => {
    queryInput = {
      ...queryInput,
      ...queryInputUpdate,
    };
    updateSchoolsDataQueryInput(queryInput);
  };

  const handlePageChange = (
    _: React.ChangeEvent,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
    updateQueryInput({
      page: value as number,
    });
  };

  const onSortChange = (sortBy: DistrictWideSchoolDetailsSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    updateQueryInput({
      page: 1,
      sort: sortBy,
      orderBy: orderBy,
    });
  };

  const handleSort = (column: DistrictWideSchoolDetailsSortType) => () => {
    if (column === queryInput.sort) {
      onSortChange(column, queryInput.orderBy === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const { data, loading: districtWideSchoolsDataLoading } = useDistrictWideSchoolDataQuery({
    fetchPolicy: 'network-only',
    variables: { ...queryInput },
    skip: !queryInput.district_id && !queryInput.school_id,
  });

  const districtWideSchoolsData = data?.districtWideSchoolData?.districtWideSchoolDetails ?? [];
  const isTableEmpty = !districtWideSchoolsDataLoading && schoolsDataCount === 0;

  const gradesQueryInput: DistrictWideQueryInput = { ...queryInput, 'sort': DistrictWideGradeSortType.GradeTitle };
  delete gradesQueryInput.limit;
  delete gradesQueryInput.page;
  const { data: districtWideGradesData, loading: districtWideGradesDataLoading } = useDistrictWideGradeDataQuery({
    fetchPolicy: 'network-only',
    variables: { 'input': gradesQueryInput },
    skip: !gradesQueryInput.district_id && !gradesQueryInput.school_id,
  });

  useEffect(() => {
    if (!districtWideSchoolsDataLoading) setSchoolsDataCount?.(data?.districtWideSchoolData?.districtWideSchoolDetailsCount);
    if (!districtWideGradesDataLoading) setGradesDataCount?.(districtWideGradesData?.districtWideGradeInfo?.length as number);
    setWidgetLoading?.(districtWideSchoolsDataLoading || districtWideGradesDataLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtWideGradesDataLoading, districtWideSchoolsDataLoading]);

  return ({
    queryInput, handleSort,
    pagination, handlePageChange,
    schoolsDataCount, districtWideSchoolsData, isTableEmpty,
  });
};

export default useSchools;
