import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Button, Checkbox, Grid, MenuItem, SxProps, TextField, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { addDays, isAfter, isBefore, isValid } from 'date-fns';
import { CreateAssignmentStylesCss } from '../../../Assignments/CreateAssignment/CreateAssignment.styled';
import { useCreateBenchmark } from '../create-benchmark-hook';
import { StepProp } from '../../../Assignments/CreateAssignment/create-assignment-hook';
import { StyledNextButton } from '../../../Assignments/Assignments.styled';
import FormProgressBar from '../../../../components/FormProgressBar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarIcon from '../../../../components/Icons/CalendarIcon';
import { BENCHMARK_COUNT_LIMIT, CreateBenchmarkSteps } from '../../../../variables/constant';
import { cleanDate } from '../../../../utils/dateFormat';
import { DateSelectorProp } from '../../../Assignments/CreateAssignment/Steps/DetailsStep';
import AssignToFilterCard from './AssignToFilterCard';
import RadioGroup from '../../../../components/Radio';
import { BenchmarkGradedBy, BenchmarkType } from '../../../../generated/graphql';
import { palette } from '../../../../theme/palette';
import useStudentsCountFilter from '../students-count-filter-hook';


const dateIconStyles = {
  customBox:{
    '& .MuiButtonBase-root.MuiIconButton-root':{
      marginRight: '0px !important',
    },
  },
};

export const GradedByOptions = Object.keys(BenchmarkGradedBy).map((user: string) => {
  if (user === 'Flashlight') return { label:'Flashlight 360', value: BenchmarkGradedBy.Flashlight };
  else return { label: user, value: BenchmarkGradedBy.Teacher };
}) as any[];

const benchmarkValues  = Object.values(BenchmarkType);
const benchmarkEoy = benchmarkValues.splice(Object.values(BenchmarkType).indexOf(BenchmarkType.Eoy), 1);
const benchmarkValuesArranged = [...benchmarkValues, ...benchmarkEoy];

export const BenchmarkTypeOptions = benchmarkValuesArranged.map((type: string) => {
  const typeValue = { value: type };
  switch (type) {
    case BenchmarkType.Boy: return { ...typeValue, label: 'BOY' };
    case BenchmarkType.MoyOne: return { ...typeValue, label: 'MOY1' };
    case BenchmarkType.MoyTwo: return { ...typeValue, label: 'MOY2' };
    case BenchmarkType.Eoy: return { ...typeValue, label: 'EOY' };
    default: return { ...typeValue, label: 'BOY' };
  }
});

const DetailsStep = ({ title }: StepProp) => {

  const theme = useTheme();
  const styles = CreateAssignmentStylesCss(theme);

  const {
    routeData,
    benchmarkInput,
    submitStep,
    backToBenchmarks,
    academicSessionEndDate,
    districts, setDistrictIDs,
    grades, setGradeIDs,
    schools, setSchoolIDs,
  } = useCreateBenchmark();

  const { studentsCount, studentCountLoading } = useStudentsCountFilter({ districts, grades, schools, currentStep: routeData?.currentStep || 1 });

  const benchmarkCountExceeded = !!studentsCount && studentsCount > BENCHMARK_COUNT_LIMIT;

  const {
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    clearErrors,
  } = useFormContext();


  const startAt = watch('start_at', benchmarkInput?.start_at);
  const closeAt = watch('close_at', benchmarkInput?.close_at);

  const currDate = new Date();

  return (
    <>
      <Box sx={styles.formatTopNavBar}>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant='secondaryTitle'>{title}</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormProgressBar
              steps={CreateBenchmarkSteps}
              progressValue={routeData?.currentStep! - 1}
            />
          </Grid>
        </Grid>
      </Box>
      <form
        name="benchmark-form"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(submitStep)}
      >
        <Box>
          <Grid container item xs={12}>
            <Grid item xs={7}>
              <Grid item xs={5} sx={styles.formItemContainer}>
                <Box m={theme.spacing(2, 0, 1, 0)}>
                  <Typography variant='sFormTitle'>Benchmark Type</Typography>
                </Box>
                <Box>
                  <Controller
                    name="benchmark_type"
                    defaultValue={benchmarkInput?.benchmark_type!}
                    render={({ onChange, value }) => (
                      <TextField
                        id="benchmark_type"
                        sx={{ width: 250 }}
                        select
                        aria-describedby="benchmark_type"
                        value={value}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChange(event.target.value); clearErrors('benchmark_type'); }}
                        defaultValue={benchmarkInput?.benchmark_type!}
                      >
                        {BenchmarkTypeOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    rules={{ required: 'Please select the benchmark type.' }}
                    control={control}
                  />
                  <Box>
                  <Typography variant="sFormHelper">
                    {errors?.benchmark_type && errors?.benchmark_type?.message}
                  </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={8} sx={styles.formItemContainer}>
                <Box m={theme.spacing(2, 0, 1, 0)}>
                  <Typography variant='sFormTitle'>Date Range<br/></Typography>
                </Box>
                <Box m={theme.spacing(1, 0, 1, 0)}>
                  <Typography variant="sFormHelperText">This is when the benchmark will available to the students.<br/></Typography>
                </Box>
                <Grid container item>
                  <Grid item xs={5} sx={{ ...dateIconStyles.customBox }}>
                    <Controller
                      name="start_at"
                      value={benchmarkInput?.start_at}
                      render={({ onChange, value }: DateSelectorProp) => (
                        <DatePicker
                          views={['day']}
                          renderInput={(params:any) => <TextField {...params} helperText={null} sx={styles.datepickerInput}/>}
                          minDate={isBefore(startAt, currDate) ? startAt : currDate}
                          maxDate={cleanDate(academicSessionEndDate)}
                          PopperProps={{
                            sx: {
                              ' & .Mui-selected': {
                                color: `${palette.customWhite.main}!important`,
                              },
                            },
                          }}
                          onChange={(date) => {
                            onChange(date);
                            clearErrors('start_at');
                          }}
                          value={cleanDate(value)}
                          components={{
                            OpenPickerIcon: CalendarIcon,
                          }}
                        />
                      )}
                      rules={{
                        required: 'Please select the start date.',
                        validate: {
                          validStartDate: (value: Date) => isValid(cleanDate(value)) || 'Please enter valid start date.',
                        },
                      }}
                      control={control}
                    />
                    <Typography variant='sFormHelper'>
                      {errors?.start_at && errors?.start_at?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography fontSize={18} fontWeight={800} align="center" m={theme.spacing(1.25, 2)}> to </Typography>
                  </Grid>
                  <Grid item xs={5} sx={{ ...dateIconStyles.customBox }}>
                    <Controller
                      name="close_at"
                      value={closeAt!}
                      render={({ onChange, value }: DateSelectorProp) => (
                        <DatePicker
                          views={['day']}
                          renderInput={(params: any) => <TextField {...params} helperText={null} sx={styles.datepickerInput} />}
                          value={cleanDate(value)}
                          minDate={addDays(startAt!, 1)}
                          maxDate={cleanDate(academicSessionEndDate)}
                          PopperProps={{
                            sx: {
                              ' & .Mui-selected': {
                                color: `${palette.customWhite.main}!important`,
                              },
                            },
                          }}
                          components={{
                            OpenPickerIcon: CalendarIcon,
                          }}
                          onChange={(date) => {
                            onChange(date);
                            clearErrors('close_at');
                          }}
                        />
                      )}
                      rules={{
                        required: 'Please select the end date.',
                        validate: {
                          dateAfter: (value: Date) => isAfter(cleanDate(value),
                            cleanDate(getValues('start_at')))
                          || 'Close date should be greater than start date.',
                          validCloseDate: (value: Date) => isValid(cleanDate(value))
                          || 'Please enter valid close date.',
                        },
                      }}
                      control={control}
                    />
                    <Typography variant='sFormHelper'>
                      {errors?.close_at && errors?.close_at?.message}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={styles.formItemContainer}>
                <Box display="flex" justifyContent="flex-start">
                  <Controller
                    name="back_navigation_allowed"
                    defaultValue={!benchmarkInput?.back_navigation_allowed ? false : benchmarkInput?.back_navigation_allowed}
                    value={!benchmarkInput?.back_navigation_allowed ? false : benchmarkInput?.back_navigation_allowed}
                    render={({ onChange, value }) => (
                      <Checkbox
                        name="back_navigation_allowed"
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    )}
                    control={control}
                  />
                  <Typography variant='sFormHelperText'>
                    Allow students to go back during the assignment.
                  </Typography>
                </Box>
              </Grid>
              <Grid item sx={styles.formItemContainer}>
                <Box m={theme.spacing(2, 0, 1, 0)}>
                  <Typography variant='sFormTitle'>Graded by</Typography>
                </Box>
                <Box>
                  <Controller
                    name="graded_by"
                    defaultValue={benchmarkInput?.graded_by!}
                    render={({ onChange, value }) => (
                      <RadioGroup
                        value={value as BenchmarkGradedBy}
                        handleChange={(data) => {
                          onChange(data);
                          clearErrors('graded_by');
                        }}
                        radios={GradedByOptions}
                        radioGroupName='graded-by-options'
                        key='graded-by'
                      />
                    )}
                    rules={{ required: 'Please select the benchmark type.' }}
                    control={control}
                  />
                  <Typography variant="sFormHelper">
                    {errors?.graded_by && errors?.graded_by?.message}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={4} ml={theme.spacing(3)} sx={styles.formItemContainer}>
              <Box>
                <Typography variant='sFormTitle'  p={theme.spacing(4, 0, 4, 0)}>
                  Assign To
                </Typography>
              </Box>
              <Box mt={2}>
                <AssignToFilterCard
                  districts={districts as string[]}
                  setDistrictIDs = {setDistrictIDs}
                  grades={grades as string[] | undefined}
                  setGradeIDs={ setGradeIDs}
                  schools={schools as string[] | undefined}
                  setSchoolIDs={setSchoolIDs}
                  studentsCount={studentsCount}
                  benchmarkCountExceeded={benchmarkCountExceeded}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Box
              sx={styles.benchmarkFormBottomNavBar as SxProps<Theme>}
            >
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => { backToBenchmarks(); }}
                >
                Back to Benchmarks
                </Button>
              </Box>
              {!studentCountLoading && <Box>
                <StyledNextButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={(!studentsCount || benchmarkCountExceeded) && !! districts.length}
                >
                Next
                </StyledNextButton>
              </Box>}
            </Box>
          </Grid>
        </Box>
      </form>
    </>
  );
};

export default DetailsStep;
