import React from 'react';
import useGoalAchievementInfo from './goal-achievement-graph';
import GoalAchievementGraphWidget from '../../../../components/AdminGraphs/GoalAchievementGraphWidget';
import { AdminDashboards } from '../../../../variables/constant';

const GoalAchievementGraph = () => {
  const {
    sessionStartDate,
    sessionEndDate,
    currStartDate,
    currEndDate,
    achievementGraphLoading,
    title,
    maxScore,
    categoricalData,
    hasData,
  } = useGoalAchievementInfo();

  return (
    <>
      <GoalAchievementGraphWidget
        sessionStartDate={sessionStartDate}
        sessionEndDate={sessionEndDate}
        currStartDate={currStartDate}
        currEndDate={currEndDate}
        data={categoricalData}
        loading={achievementGraphLoading}
        title={title}
        alignTitle
        maxScore={maxScore}
        tooltipTitle={AdminDashboards.learningGoals.GoalAchievementWidget.tooltipText}
        enableTooltip
        hasData={hasData}
      />
    </>
  );
};

export default GoalAchievementGraph;
