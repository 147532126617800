import React, { useEffect, useState } from 'react';
import { sub, getUnixTime, fromUnixTime, max, endOfDay, startOfDay } from 'date-fns';
import {
  SubmissionType,
  AdminDashboardFilterInput,
  AdminDashboardSectionFragmentFragment,
  PredefinedGrades,
  SchoolFilterFragmentFragment,
  TeacherFilterFragmentFragment,
} from '../../../generated/graphql';
import { AdminAnalyticsDateFilterOptions } from '../../../variables/types';
import { AnalyticsFiltersProps, useDashboardContext } from '../admin-analytics-hook';

const useAnalyticsFilters = () => {
  const today = new Date();

  const {
    loading, setLoading,
    academicSessionLoading, academicSession, sessionStartDate, startDateInit,
    dashboardFilters,
    currentFilters, setCurrentFilters,
    analyticsFilters, setAnalyticsFilters,
    resetDashboardWidgetsDataStatus,
    setLocalStorageDashboardFilters,
  } = useDashboardContext();

  const updateLoader = (loadingStatus: boolean) => setLoading?.(loading && loadingStatus);

  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const toggleFiltersDisplay = () => {
    setOpenFilters(!openFilters);
  };

  const [applyFilters, setApplyFilters] = useState<boolean>(false);
  const toggleApplyFilters = () => {
    setApplyFilters(!applyFilters);
  };

  const [resetFilters, setResetFilters] = useState<boolean>(false);
  const toggleResetFilters = () => {
    setResetFilters(!revertFilters);
  };

  const [revertFilters, setRevertFilters] = useState<boolean>(false);
  const toggleRevertFilters = () => {
    setRevertFilters(!revertFilters);
  };

  const handleDialogOnClose = (
    _: any,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason && ['backdropClick', 'escapeKeyDown'].includes(reason)) setOpenFilters(openFilters);
    else toggleFiltersDisplay();
  };

  const [dateRangeSelected, setDateRangeSelected] = useState<string>(dashboardFilters?.dateRange!);
  const [submissionTypeSelected, setSubmissionTypeSelected] = useState<SubmissionType>(dashboardFilters?.submissionType!);
  const [schoolsSelected, setSchoolsSelected] = useState<SchoolFilterFragmentFragment[]>(dashboardFilters?.schools!);
  const [gradesSelected, setGradesSelected] = useState<PredefinedGrades[]>(dashboardFilters?.grades!);
  const [teachersSelected, setTeachersSelected] = useState<TeacherFilterFragmentFragment[]>(dashboardFilters?.teachers!);
  const [classesSelected, setClassesSelected] = useState<AdminDashboardSectionFragmentFragment[]>(dashboardFilters?.classes!);
  const [startDateSelected, setStartDateSelected] = useState<Date>(dashboardFilters?.startDate!);
  const [endDateSelected, setEndDateSelected] = useState<Date>(dashboardFilters?.endDate!);

  // Tab Toggle state
  const getIndexByTabKey = (tabKey: string) => {
    var tabIndex = 0;
    switch (tabKey) {
      case SubmissionType.Writing:
        tabIndex = 1;
        break;
      case SubmissionType.Speaking:
      default:
        tabIndex = 0;
    }
    return tabIndex;
  };
  const [tab, setTab] = useState<number>(getIndexByTabKey(submissionTypeSelected));

  // Filters onChange Handlers

  // Submission Type
  const handleSubmissionTypeSelectedChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    switch (newValue) {
      case 1:
        setSubmissionTypeSelected(SubmissionType.Writing);
        toggleApplyFilters();
        return;
      case 0:
      default:
        setSubmissionTypeSelected(SubmissionType.Speaking);
        toggleApplyFilters();
        return;
    }
  };

  // DateRange
  const handleDateRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateRangeSelected?.((event.target as HTMLInputElement).value);
  };

  // Schools
  const updateSchoolsSelected = (selectedSchools: SchoolFilterFragmentFragment[]) => {
    setSchoolsSelected(selectedSchools);
  };
  // Grades
  const updateGradesSelected = (selectedGrades: PredefinedGrades[]) => {
    setGradesSelected(selectedGrades);
  };
  const gradesToEnable = (selectedSchools: SchoolFilterFragmentFragment[]) => {
    if (!selectedSchools) return [];
    const schoolGrades = new Set<string>();
    selectedSchools?.forEach((school: SchoolFilterFragmentFragment) => {
      school?.school_predefined_grade_mappings?.forEach((schoolGradeItem: any) => {
        schoolGrades.add(schoolGradeItem?.predefined_grade_id);
      });
    });
    return [...schoolGrades];
  };
  // Teachers
  const updateTeachersSelected = (selectedTeachers: TeacherFilterFragmentFragment[]) => {
    setTeachersSelected(selectedTeachers);
  };
  // Classes
  const updateClassesSelected = (selectedClasses: AdminDashboardSectionFragmentFragment[]) => {
    setClassesSelected(selectedClasses);
  };

  // Date Range Dates Helper
  const updateDateFilterRange = (newAnalyticsFilters: AdminDashboardFilterInput, dateRange: string = dateRangeSelected) => {
    switch (dateRange) {
      case AdminAnalyticsDateFilterOptions.CustomDateRange:
        newAnalyticsFilters.start_timestamp = getUnixTime(startOfDay(startDateSelected));
        newAnalyticsFilters.end_timestamp = getUnixTime(endOfDay(endDateSelected));
        if (analyticsFilters?.start_timestamp === newAnalyticsFilters.start_timestamp) newAnalyticsFilters.start_timestamp += 1;
        break;
      // case AdminAnalyticsDateFilterOptions.LastYear:
      //   break;
      case AdminAnalyticsDateFilterOptions.ThisMonth:
        var startDate = sub(today, {
          days: today.getDate() - 1,
        });
        newAnalyticsFilters.start_timestamp = getUnixTime(startOfDay(max([startDate, sessionStartDate])));
        newAnalyticsFilters.end_timestamp = getUnixTime(endOfDay(today));
        break;
      case AdminAnalyticsDateFilterOptions.Last30Days:
        startDate = sub(today, {
          days: 30,
        });
        newAnalyticsFilters.start_timestamp = getUnixTime(startOfDay(max([startDate, sessionStartDate])));
        newAnalyticsFilters.end_timestamp = getUnixTime(endOfDay(today));
        break;
      default:
        // Last 3 months
        startDate = sub(today, {
          months: 3,
        });
        newAnalyticsFilters.start_timestamp = getUnixTime(startOfDay(max([startDate, sessionStartDate])));
        newAnalyticsFilters.end_timestamp = getUnixTime(endOfDay(today));
    }
  };

  const applyAnalyticsFilters = async ({
    dateRange, submissionType,
    schools, grades,
    teachers, classes,
    startDate, endDate,
  }: AnalyticsFiltersProps) => {
    updateLoader(true);
    resetDashboardWidgetsDataStatus(true);  // has to be before the setAnalyticsFilters
    setTimeout(() => {
      const newAnalyticsFilters: AdminDashboardFilterInput = {};
      newAnalyticsFilters.submission_score_type = submissionType;
      if (schools && schools.length)
        newAnalyticsFilters.school_ids = schools?.map((school: SchoolFilterFragmentFragment) => school?.id);
      if (grades && grades.length)
        newAnalyticsFilters.grade_ids = grades?.map((grade: PredefinedGrades) => grade.id);
      if (teachers && teachers.length)
        newAnalyticsFilters.teacher_ids = teachers?.map((teacher: TeacherFilterFragmentFragment) => teacher.id);
      if (classes && classes.length)
        newAnalyticsFilters.section_ids = classes?.map((tclass: AdminDashboardSectionFragmentFragment) => tclass.id);
      updateDateFilterRange(newAnalyticsFilters, dateRange);
      if (!startDate) startDate = fromUnixTime(newAnalyticsFilters.start_timestamp!);
      if (!endDate) endDate = fromUnixTime(newAnalyticsFilters.end_timestamp!);
      // Update Local Storage with new selected Filters
      setLocalStorageDashboardFilters?.({
        dateRange: dateRange,
        submissionType: submissionType,
        schools: schools,
        grades: grades,
        teachers: teachers,
        classes: classes,
        startDate: startDate,
        endDate: endDate,
      });
      // update analyticsFilters in context that act's as a base to queryFilters in all widgets
      setAnalyticsFilters?.({
        ...newAnalyticsFilters,
      });
    }, 100);
  };

  // reset filters to default state
  const resetAnalyticsFilters = async () => {
    updateLoader(true);
    setDateRangeSelected(AdminAnalyticsDateFilterOptions.CustomDateRange);
    setSchoolsSelected([]);
    setGradesSelected([]);
    setTeachersSelected([]);
    setClassesSelected([]);
    setStartDateSelected(sessionStartDate);
    setEndDateSelected(today);
    // Apply the reset filters
    toggleApplyFilters();
  };

  // revert filters to the previous state by referring to context when user has not applied modified filters
  const revertAnalyticsFilters = async ({
    dateRange = dateRangeSelected,
    schools = schoolsSelected,
    grades = gradesSelected,
    teachers = teachersSelected,
    classes = classesSelected,
  }: AnalyticsFiltersProps) => {
    if (dateRange) setDateRangeSelected(dateRange);
    if (schools) setSchoolsSelected(schools);
    if (grades) setGradesSelected(grades);
    if (teachers) setTeachersSelected(teachers);
    if (classes) setClassesSelected(classes);
  };

  // Filter Deleters
  const delSchoolSelected = (schoolId?: string) => {
    const newSchoolsSelected = !schoolId ? [] : schoolsSelected.filter((school) => school?.id !== schoolId);
    setSchoolsSelected(newSchoolsSelected);
    toggleApplyFilters();
  };
  const delGradeSelected = (gradeId?: string) => {
    const newGradesSelected = !gradeId ? [] : gradesSelected.filter((grade) => grade?.id !== gradeId);
    setGradesSelected(newGradesSelected);
    toggleApplyFilters();
  };
  const delTeacherSelected = (teacherId?: string) => {
    const newTeachersSelected = !teacherId ? [] : teachersSelected.filter((teacher) => teacher?.id !== teacherId);
    setTeachersSelected(newTeachersSelected);
    toggleApplyFilters();
  };
  const delClassSelected = (classId?: string) => {
    const newClassesSelected = !classId ? [] : classesSelected.filter((tClass) => tClass.id !== classId);
    setClassesSelected(newClassesSelected);
    toggleApplyFilters();
  };
  const resetDateRangeSelected = () => {
    const newDateRangeSelected = AdminAnalyticsDateFilterOptions.CustomDateRange;
    setDateRangeSelected(newDateRangeSelected);
    if (startDateSelected === startDateInit) return;
    toggleApplyFilters();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!applyFilters) return;
    applyAnalyticsFilters({
      dateRange: dateRangeSelected,
      submissionType: submissionTypeSelected,
      schools: schoolsSelected,
      grades: gradesSelected,
      teachers: teachersSelected,
      classes: classesSelected,
    });
    toggleApplyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyFilters]);

  // revert filters to the previous state by referring to context when user has not applied modified filters
  useEffect(() => {
    if (resetFilters) {
      resetAnalyticsFilters();
      toggleResetFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFilters]);

  // revert filters to the previous state by referring to context when user has not applied modified filters
  useEffect(() => {
    if (revertFilters) {
      revertAnalyticsFilters({
        ...currentFilters,
      });
      toggleRevertFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revertFilters]);

  // save the current filters state in context before allowing user to modify filters
  useEffect(() => {
    if (openFilters) {
      setCurrentFilters?.({
        dateRange: dateRangeSelected,
        submissionType: submissionTypeSelected,
        schools: schoolsSelected,
        grades: gradesSelected,
        teachers: teachersSelected,
        classes: classesSelected,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openFilters,
  ]);

  return ({
    tab,
    openFilters, toggleFiltersDisplay, handleDialogOnClose,
    applyFilters, toggleApplyFilters,
    resetFilters, toggleResetFilters,
    revertFilters, toggleRevertFilters,
    submissionTypeSelected, handleSubmissionTypeSelectedChange,
    schoolsSelected, updateSchoolsSelected, delSchoolSelected,
    gradesSelected, updateGradesSelected, gradesToEnable, delGradeSelected,
    teachersSelected, updateTeachersSelected, delTeacherSelected,
    classesSelected, updateClassesSelected, delClassSelected,
    startDateSelected, setStartDateSelected,
    endDateSelected, setEndDateSelected, updateDateFilterRange,
    dateRangeSelected, handleDateRangeChange, resetDateRangeSelected,
    applyAnalyticsFilters, resetAnalyticsFilters,
    revertAnalyticsFilters,
    academicSessionLoading, academicSession,
    updateLoader,
  });
};

export default useAnalyticsFilters;
