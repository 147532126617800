import React, { useContext } from 'react';
import { Box, TextField } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { isIOS } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import NextButton from '../Common/NextButton';
import { SubmissionStatus } from '../../../generated/graphql';
import useWritingTask from './writing-hook';
import BackButton from '../Common/BackButton';
import SaveAndQuitButon from '../Common/SaveAndQuitButon';
import { SubmitTaskContext } from '../submit-task-context';
import useSubmitTask from '../submit-task-hook';

const styles = {
  textBox: {
    '&.MuiInputBase-root': {
      width: '600px',
    },
  },
  input: {
    '& .MuiInputBase-input': {
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
    },
  },
  iOSBox: {
    width: '600px',
    minHeight: '145px',
    height: '145px',
    wordBreak: 'break-word',
    overflowY: 'scroll',
    padding: 0,
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      height: '145px',
      overflowY: 'scroll',
      alignItems: 'flex-start',
    },
  },
  inputiOS: {
    '& .MuiInputBase-input': {
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
      height: '145px',
      width: '600px',
      wordBreak: 'break-word',
      overflowY: 'scroll',
      verticalAlign: 'top',
      border: 'none',
      padding: '0px 5px',
    },
  },
};

const WritingTask = () => {
  const {
    id,
    submission,
    isSpeakingWritingPromptPlaying,
  } = useContext(SubmitTaskContext);
  const {
    writing,
    setWriting,
    updateWriting,
  } = useWritingTask();
  const history = useHistory();

  const {
    updateSubmissionStatus,
  } = useSubmitTask();

  const handleNext = async () => {
    await updateSubmissionStatus(SubmissionStatus.Submitted, writing);
  };
  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr 1fr"
      justifyContent="center"
      alignItems="end"
    >

      <Box display="flex" justifyContent="flex-start" marginBottom={2}>
        <Box>
          {
            (submission?.getBenchmark?.back_navigation_allowed || submission?.assignment?.back_navigation_allowed) && (
              <BackButton handleBack={() => history.push(`/tasks/${id}/submit/speaking`)}  disabled={isSpeakingWritingPromptPlaying} />
            )
          }

        </Box>
        <Box ml="5px">
          <SaveAndQuitButon handleSave={updateWriting} />
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          {/* The 'data-gramm': false prop is added to disable Grammarly extension check,
          For any similar extension we may have to find the prop which works with that extension.
           This is subject to change as it is highly dependent on the extension. */}
          {
            !isIOS && (
              <InputBase
                placeholder="Type Here"
                value={writing || ''}
                multiline
                rows={5}
                inputProps={{
                  spellCheck: 'false',
                  'data-gramm': false,
                }}
                sx={{ ...styles.textBox, ...styles.input }}
                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setWriting(event.target.value)}
              />
            )
          }
          {
            isIOS && (
              <TextField
                multiline
                placeholder="Type Here"
                value={writing || ''}
                autoFocus
                sx={{ ...styles.iOSBox, ...styles.inputiOS }}
                spellCheck="false"
                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setWriting(event.target.value)}
                InputProps={{
                  sx: {
                    alignItems: 'flex-start',
                  },
                }}
              />
            )
          }
        </Box>
      </Box>
      <Box
        alignItems="flex-end"
        display="flex"
        justifyContent="end"
        marginBottom={2}
      >
        <NextButton
          status={SubmissionStatus.SpeakingCompleted}
          handleNext={handleNext}
          showConfirmationDialog={!writing?.length || !writing?.trim()?.length}
          nextBtnLabel="Submit"
          disabled={isSpeakingWritingPromptPlaying}
        />
      </Box>
    </Box>
  );
};

export default React.memo(WritingTask);
