import React from 'react';
import {
  Theme, SxProps,
  Typography, Box,
  Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button,
  IconButton,
  Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SyncIcon from '@mui/icons-material/Sync';
import { useTheme } from '@mui/material/styles';
import {
  AnalyticsFiltersStylesCSS,
  StyledAnalyticsFiltersTabs,
  StyledAnalyticsFiltersTab,
  StyledFilterButton,
} from './AnalyticsFilters.styled';
import SchoolFilterWithMultiSelect from '../../../components/SchoolFilterWithMultiSelect';
import SelectGradeMultiSelect from '../../../components/SelectGradeMultiSelect';
import TeacherSelectMultiple from '../../../components/TeacherSelectMultiple';
import SectionsMultiSelectFilter from '../../../components/SectionsMultiSelectFilter';
import { AdminAnalyticsDateFilterOptions, AdminAnalyticsFilterTypes, SubmissionTypes } from '../../../variables/types';
import useAnalyticsFilters from './analytics-filters-hook';
import {
  PredefinedGrades,
  SchoolFilterFragmentFragment,
  TeacherFilterFragmentFragment,
  AdminDashboardSectionFragmentFragment,
  SectionCategory,
} from '../../../generated/graphql';
import AdminAnalyticsDateFilter from '../../../components/AdminAnalyticsDateFilter';
import { useDashboardContext } from '../admin-analytics-hook';
import ExportToPdf from './pdfExport';
import { parseISO } from 'date-fns/esm';

interface RenderFilterChipsProps {
  filterType: string,
  filters: any,
  handleDeleteChip: Function,
}

const AnalyticsFilters = () => {
  const theme = useTheme();
  const styles = AnalyticsFiltersStylesCSS(theme);

  const {
    isSchoolAdmin,
    myDistrictData,
    mySchoolData,
    areWidgetsLoading,
    isDataMissingInAllWidgets,
  } = useDashboardContext();

  const {
    openFilters, toggleFiltersDisplay, handleDialogOnClose,
    toggleApplyFilters, toggleResetFilters, toggleRevertFilters,
    tab, handleSubmissionTypeSelectedChange, submissionTypeSelected,
    schoolsSelected, updateSchoolsSelected, delSchoolSelected,
    gradesSelected, updateGradesSelected, gradesToEnable, delGradeSelected,
    teachersSelected, updateTeachersSelected, delTeacherSelected,
    classesSelected, updateClassesSelected, delClassSelected,
    startDateSelected, setStartDateSelected,
    endDateSelected, setEndDateSelected,
    dateRangeSelected, handleDateRangeChange, resetDateRangeSelected,
    academicSession,
    updateLoader,
  } = useAnalyticsFilters();

  const districtId = isSchoolAdmin ? mySchoolData?.district_id : myDistrictData?.id!;
  const schoolId = mySchoolData?.id!;

  const limitMultiSelectTagsTo: number = 1;

  const RenderFilterChips = ({ filterType, filters, handleDeleteChip }: RenderFilterChipsProps) => {
    return (
      <>
        {filterType === AdminAnalyticsFilterTypes.DateRange && (
          filters === AdminAnalyticsDateFilterOptions.CustomDateRange
            ? <Chip label={
              <Typography variant='filterChip'> {filters}</Typography>
            } disabled={areWidgetsLoading} onDelete={() => handleDeleteChip()} sx={styles.filterChip as SxProps} />
            : <Chip label={
              <Typography variant='filterChip'> {filters}</Typography>
            } disabled={areWidgetsLoading} onDelete={() => handleDeleteChip()} sx={styles.filterChip as SxProps} />
        )}
        {filterType !== AdminAnalyticsFilterTypes.DateRange && (
          <Chip label={
            <Typography variant='filterChip'> {`${filterType} (${filters.length > 0 ? filters.length : 'All'})`}</Typography>
          } disabled={areWidgetsLoading} onDelete={() => handleDeleteChip()} sx={styles.filterChip as SxProps} />
        )}
      </>
    );
  };

  return (
    <>
      <Box
        sx={styles.filterBox as SxProps<Theme>}
      >
        <StyledAnalyticsFiltersTabs
          value={tab}
          onChange={handleSubmissionTypeSelectedChange}
          textColor="primary"
          scrollButtons={false}
          id="admin-analytics-filter-tabs"
        >
          {
            Object.entries(SubmissionTypes).map(([tabLabel, tabKey]) => {
              return (
                <StyledAnalyticsFiltersTab
                  key={tabKey as string}
                  classes={{
                    root: `${tabKey as string}`,
                  }}
                  label={(
                    <Typography>
                      <Typography component="span" variant='title18'>
                        {tabLabel as string}
                      </Typography>
                    </Typography>
                  )}
                  disabled={areWidgetsLoading}
                  disableRipple
                />
              );
            })
          }
        </StyledAnalyticsFiltersTabs>
        <StyledFilterButton
          variant="contained"
          color={openFilters ? 'primary' : 'inherit'}
          sx={{
            marginRight: '8px',
            ...(openFilters ? { border: 'none' } : {}),
          }}
          onClick={toggleFiltersDisplay} startIcon={<FilterAltIcon />}
          className="print-ignore"
        >
          Filters
        </StyledFilterButton>
        <ExportToPdf
          disable={isDataMissingInAllWidgets!}
          dateRange={dateRangeSelected}
          submissionType={submissionTypeSelected}
          schools={schoolsSelected}
          grades={gradesSelected}
          teachers={teachersSelected}
          classes={classesSelected}
        />
      </Box>
      <Box
        sx={styles.filterChipBox as SxProps<Theme>}
      >
        <Box id="admin-analytics-filter-chips">
          <RenderFilterChips
            filterType={AdminAnalyticsFilterTypes.DateRange}
            filters={dateRangeSelected}
            handleDeleteChip={resetDateRangeSelected} />
          <RenderFilterChips
            filterType={AdminAnalyticsFilterTypes.Schools}
            filters={schoolsSelected}
            handleDeleteChip={delSchoolSelected} />
          <RenderFilterChips
            filterType={AdminAnalyticsFilterTypes.Grades}
            filters={gradesSelected}
            handleDeleteChip={delGradeSelected} />
          <RenderFilterChips
            filterType={AdminAnalyticsFilterTypes.Teachers}
            filters={teachersSelected}
            handleDeleteChip={delTeacherSelected} />
          <RenderFilterChips
            filterType={AdminAnalyticsFilterTypes.Classes}
            filters={classesSelected}
            handleDeleteChip={delClassSelected} />
        </Box>
        <Chip
          variant="outlined"
          label={
            <Typography variant='filterChip'>Clear All Filters</Typography>
          }
          disabled={areWidgetsLoading}
          onClick={toggleResetFilters}
          icon={<SyncIcon color="primary" fontSize="medium" />}
          sx={{
            ...styles.filterChip,
            backgroundColor: `${theme.palette.customWhite.main} !important`,
          }}
          size="medium"
          className="print-ignore"
        />
      </Box>
      <Dialog
        disableEscapeKeyDown
        onClose={handleDialogOnClose}
        fullWidth
        maxWidth="xl"
        open={openFilters}
        PaperProps={{
          sx: styles.filterDialogPaperSx as SxProps<Theme>,
        }}
        sx={{
          ...styles.filterDialog as SxProps<Theme>,
        }}
      >
        <DialogTitle sx={styles.filterDialogTitle}>
          <Typography component="span" variant='font28'>
            <FilterAltIcon fontSize='large' sx={{ mb: -1 }} />
            Filters
          </Typography>
          <IconButton
            color="inherit"
            onClick={() => {
              toggleFiltersDisplay();
              toggleRevertFilters();
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <SchoolFilterWithMultiSelect
                value={ isSchoolAdmin ? [schoolId] : schoolsSelected?.map((school: SchoolFilterFragmentFragment) => school?.id)}
                limitTags={limitMultiSelectTagsTo}
                textInputPlaceholder="All Schools"
                onSelectedSchoolsChange={(schoolList) => {
                  updateSchoolsSelected(schoolList);
                }}
                districts={[districtId] as string[]}
                schoolsToEnable={isSchoolAdmin ? [schoolId] : []}
                updateLoader={updateLoader}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <SelectGradeMultiSelect
                value={gradesSelected?.map((grade: PredefinedGrades) => grade.id)}
                textInputPlaceholder="All Grades"
                limitTags={limitMultiSelectTagsTo}
                onSelectedGradesChange={updateGradesSelected}
                gradesToEnable={gradesToEnable(schoolsSelected)}
                placeholder={'All Grades'}
                districtId={districtId}
                schoolIds={isSchoolAdmin ? [schoolId] : schoolsSelected?.map((school: SchoolFilterFragmentFragment) => school?.id)}
                updateLoader={updateLoader}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <TeacherSelectMultiple
                filterLabel=""
                limitTags={limitMultiSelectTagsTo}
                textInputPlaceholder="All Teachers"
                value={teachersSelected?.map((teacher: TeacherFilterFragmentFragment) => teacher.id)}
                onSelectedTeachersChange={updateTeachersSelected}
                districtId={districtId as string}
                schoolId={schoolId as string}
                schoolsInScope={isSchoolAdmin ? [schoolId] : schoolsSelected?.map((school: SchoolFilterFragmentFragment) => school?.id)}
                updateLoader={updateLoader}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <SectionsMultiSelectFilter
                limitTags={limitMultiSelectTagsTo}
                textInputPlaceholder="All Classes"
                value={classesSelected?.map((value: AdminDashboardSectionFragmentFragment) => value.id)}
                onSelectedClassesChange={updateClassesSelected}
                districtId={districtId}
                schoolId={isSchoolAdmin ? [schoolId] : schoolsSelected?.map((school: SchoolFilterFragmentFragment) => school?.id)}
                classTypes={[SectionCategory.Class, SectionCategory.CustomClass]}
                teacherId={teachersSelected?.map(teacher => teacher.id)}
                gradeId={gradesSelected?.map(grade => grade.id)}
                updateLoader={updateLoader}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <AdminAnalyticsDateFilter
                sessionStartDate={parseISO(academicSession?.session_start_date)}
                dateRangeSelected={dateRangeSelected}
                handleDateRangeChange={handleDateRangeChange}
                startDateSelected={startDateSelected}
                setStartDateSelected={setStartDateSelected}
                endDateSelected={endDateSelected}
                setEndDateSelected={setEndDateSelected}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pv: 2, ph: 4, mt: 2 }}>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <StyledFilterButton
                variant="contained"
                color={!openFilters ? 'inherit' : 'inherit'}
                startIcon={<SyncIcon color="primary" fontSize="medium" />}
                disabled={areWidgetsLoading}
                onClick={() => {
                  toggleRevertFilters();
                }}
              >
                Reset all filters
              </StyledFilterButton>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={areWidgetsLoading}
                onClick={() => {
                  toggleApplyFilters();
                  toggleFiltersDisplay();
                }}
              >
                Apply filters
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AnalyticsFilters;
