import { matchPath, useHistory } from 'react-router-dom';
import { useAuthProvider } from '../../core/authContext';
import { NotifierType, ScoreTabs } from '../../variables/types';
import { openSnackbar } from '../../components/Notifier';
import { GRADES_FOR_CELEBRATION, ErrInvalidQueueNavigation, ErrQueueNotCreated } from '../../variables/constant';
import RoutingPaths from '../RoutingPath';

interface QueueCounter {
  graded: number,
  goalsSet: number
}

const useSubmissionQueueHook = (readOnly = true) => {
  const history = useHistory();
  const { getUser } = useAuthProvider();
  let submissionIDsQueue: string[] = [];

  // Returns local storage key based on current user ID
  const getQueueKey = () => {
    const user = getUser();

    return `teacher-submission-queue-${user?.id}`;
  };

  const getQueueCounterKey = () => {
    const user = getUser();
    return `submission-queue-details-${user?.id}`;
  };

  const getQueueCounterStorage = () => {
    const value = localStorage.getItem(getQueueCounterKey());
    if (value) {
      return JSON.parse(value);
    }
    return null;
  };

  const setQueueCounter = (value: QueueCounter = { graded: 0, goalsSet: 0 }) => {
    const counterQueue = getQueueCounterStorage();
    if (counterQueue && counterQueue.graded < GRADES_FOR_CELEBRATION) {
      localStorage.setItem(getQueueCounterKey(), JSON.stringify({
        graded: counterQueue.graded + value.graded,
        goalsSet: counterQueue.goalsSet + value.goalsSet,
      }));
    } else {
      localStorage.setItem(getQueueCounterKey(), JSON.stringify(value));
    }
  };

  const getGradedCount = () => (getQueueCounterStorage() ? getQueueCounterStorage().graded : 0);
  const getGoalsCount = () => (getQueueCounterStorage() ? getQueueCounterStorage().goalsSet : 0);

  const clearQueueCounter = () => {
    localStorage.removeItem(getQueueCounterKey());
  };

  // Accepts an array of string, converts it into JSON string and stores in local storage
  const setQueue = (value: string[]) => {
    submissionIDsQueue = value;
    const counterQueue = getQueueCounterStorage();
    localStorage.setItem(getQueueKey(), JSON.stringify(value));
    if (!counterQueue) setQueueCounter();
  };

  // Reads JSON string from local storage, converts and returns as a string
  const getQueueFromStorage = (): string[] => {
    const value = localStorage.getItem(getQueueKey());
    if (value) {
      return JSON.parse(value);
    }

    return [];
  };

  if (readOnly) {
    setQueue(getQueueFromStorage());
  }

  //
  const startQueue = (submissionIDs: string[], submissionID: string) => {
    // Initialize current submission pointer
    setQueue(submissionIDs);

    if (submissionIDs) {
      // Find index of current submission ID
      const currentSubmissionIndex = submissionIDs.findIndex((value: string) => value === submissionID);

      submissionIDs.splice(0, currentSubmissionIndex);
      setQueue(submissionIDs);
    }
  };

  const isFirst = (submissionID: string): boolean => submissionIDsQueue?.length > 0 && submissionIDsQueue[0] === submissionID;

  const isLast = (submissionID: string): boolean => submissionIDsQueue.length > 0 && submissionIDsQueue[submissionIDsQueue.length - 1] === submissionID;

  const getIndexBySubmissionID = (submissionID: string): number => submissionIDsQueue.findIndex(((value) => value === submissionID));

  // Accepts current submission ID and validates it be part of active queue
  const isValid = (submissionID: string): boolean => {
    const currentSubmissionIDQueueIndex = getIndexBySubmissionID(submissionID);
    return currentSubmissionIDQueueIndex >= 0;
  };

  const clearQueue = () => {
    localStorage.removeItem(getQueueKey());
    clearQueueCounter();
  };

  const navigateToDashboard = () => history.push(RoutingPaths.Root);

  const navigateToAssignments = () => history.push(RoutingPaths.Assignments);

  const navigationOnExitQueue = (isPTList: boolean) => {
    if (isPTList) navigateToAssignments();
    else navigateToDashboard();
  };

  const next = (submissionID: string): string => submissionIDsQueue[getIndexBySubmissionID(submissionID) + 1];

  const previous = (submissionID: string): string => submissionIDsQueue[getIndexBySubmissionID(submissionID) - 1];

  const showError = () => openSnackbar({ message: ErrInvalidQueueNavigation }, NotifierType.Error);

  // Accepts current submissionID and redirects to the next (whichever comes first) section or submission ID in the queue
  const handleNext = (submissionID: string, isPTlist = false) => {
    if (isLast(submissionID)) {
      clearQueue();
      navigationOnExitQueue(isPTlist);
      return;
    }
    const nextSubmissionID = next(submissionID);
    if (!isValid(nextSubmissionID)) {
      showError();
    }

    if (getGradedCount() === GRADES_FOR_CELEBRATION) setQueueCounter();

    history.push(`/tasks/${nextSubmissionID}/score/${ScoreTabs.Speaking}`);
  };

  const isSpeakingSection = matchPath(history.location.pathname, `/tasks/:id/score/${ScoreTabs.Speaking}`);

  // Accepts current submissionID and redirects to the previous submission ID in the queue
  const handlePrevious = (submissionID: string) => {
    if (isSpeakingSection) {
      const previousSubmissionID = previous(submissionID);
      if (!isValid(previousSubmissionID)) {
        showError();
        return;
      }
      history.push(`/tasks/${previousSubmissionID}/score/${ScoreTabs.Writing}`);
    } else {
      history.push(`/tasks/${submissionID}/score/${ScoreTabs.Speaking}`);
    }
  };

  // Accepts current submissionID and redirects to the dashboard if it is not part of the current active queue
  const handleMissingQueue = (submissionID: string) => {
    if (!isValid(submissionID)) {
      openSnackbar({ message: ErrQueueNotCreated }, NotifierType.Error);
      navigateToDashboard();
    }
  };

  const handlePreviousSubmission = (submissionID: string) => {
    const previousSubmissionID = previous(submissionID);
    if (!isValid(previousSubmissionID)) {
      showError();
      return;
    }
    history.push(`/tasks/${previousSubmissionID}/score/${ScoreTabs.Speaking}`);
  };

  return {
    isValid,
    isFirst,
    isLast,
    handleNext,
    handlePrevious,
    isSpeakingSection,
    handleMissingQueue,
    endQueue: clearQueue,
    initializeSubmissionQueue: startQueue,
    setQueueCounter,
    getGradedCount,
    getGoalsCount,
    handlePreviousSubmission,
    navigationOnExitQueue,
  };
};

export default useSubmissionQueueHook;
