import React, { forwardRef, useEffect, useRef } from 'react';
import { Popover, PopupButton, Sidetab, SliderButton } from '@typeform/embed-react';
import { Box } from '@mui/material';

//Below props description closely relate to https://www.typeform.com/developers/embed/configuration
interface Props {
  formId: string;
  hiddenButton?: boolean;
  delay?: number;
  typeformLayout?: 'popup' | 'slider' | 'sidetab' | 'popover';
  buttonText?: string;
  onReadyHandler?: Function;
  onCloseHandler?: Function;
  onStartedHandler?: Function;
  onQuestionChangedHandler?: Function;
  onFormSubmittedHandler?: Function;
  notificationDays?: number;
  shareGaInstance?: string;
  keepSession?: boolean;
  hiddenFields?: any;
  height?: number;
  width?: number;
}

const PopupButtonRef = forwardRef(({ formId, hiddenButton, buttonText, closeHandler, submitHandler }:any, ref) => (
  <PopupButton
    ref={ref}
    id={formId}
    onClose={closeHandler}
    onSubmit={submitHandler}
    height={700}
    width={950}
    style={{ fontSize: 20, visibility: hiddenButton ? 'hidden' : 'visible' }}
    className="typrform-button">
    {buttonText}
  </PopupButton>
));

const PopoverRef = forwardRef(({ formId, hiddenButton, buttonText, closeHandler, submitHandler, hiddenFields }:any, ref) => (
  <Popover
    ref={ref}
    id={formId}
    style={{ fontSize: 20, visibility: hiddenButton ? 'hidden' : 'visible' }}
    onClose={closeHandler}
    onSubmit={submitHandler}
    hidden={hiddenFields}
    className="typrform-button">
    {buttonText}
  </Popover>
));

const SliderButtonRef = forwardRef(({ formId, hiddenButton, buttonText }:any, ref) => (
  <SliderButton
    ref={ref}
    id={formId}
    style={{ fontSize: 20, visibility: hiddenButton ? 'hidden' : 'visible' }}
    className="typrform-button">
    {buttonText}
  </SliderButton>
));

const SideTabButtonRef = forwardRef(({ formId, hiddenButton, buttonText }:any, ref) => (
  <Sidetab
    ref={ref}
    id={formId}
    style={{ fontSize: 20, visibility: hiddenButton ? 'hidden' : 'visible' }}
    className="typrform-button">
    {buttonText}
  </Sidetab>
));

const TypeformEmbed = ({
  formId,
  hiddenButton,
  delay = 0,
  typeformLayout,
  buttonText,
  onCloseHandler,
  onFormSubmittedHandler,
  hiddenFields,
}: Props) => {
  const ref = useRef();

  useEffect(() => {
    const timer = setTimeout(() => ref?.current?.open(), delay);
    return () => clearTimeout(timer);
  }, []);

  const renderForm = () => {
    switch (typeformLayout) {
      case 'popup':
        return (<PopupButtonRef ref={ref} formId={formId} buttonText={buttonText} hiddenButton={hiddenButton} closeHandler={onCloseHandler} submitHandler={onFormSubmittedHandler}/>);
      case 'slider':
        return (<SliderButtonRef ref={ref} formId={formId} buttonText={buttonText} hiddenButton={hiddenButton} />);
      case 'popover':
        return (<PopoverRef ref={ref} formId={formId} buttonText={buttonText} hiddenButton={hiddenButton} closeHandler={onCloseHandler} submitHandler={onFormSubmittedHandler} hiddenFields={hiddenFields}/>);
      case 'sidetab':
        return (<SideTabButtonRef ref={ref} formId={formId} buttonText={buttonText} hiddenButton={hiddenButton} />);
      default:
        return (<PopupButtonRef ref={ref} formId={formId} buttonText={buttonText} hiddenButton={hiddenButton} />);
    }
  };


  return (
    <Box>
      {renderForm()}
    </Box>
  );
};

export default TypeformEmbed;
