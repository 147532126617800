import React from 'react';
import { Box } from '@mui/material';
import useDistrict from './district-hook';
import Loader from '../../../components/Loader/loader';
import PencilIcon from '../../../components/Icons/PencilIcon';
import StyledBoxWrapper from '../../../components/StyledCard/StyledCard.styled';
import StateIcon from '../../../components/Icons/StateIcon';
import ResourceDetailHeader from './resource-detail-header';
import ResourceDetail from './resource-detail';
import DistrictsIcon from '../../../components/Icons/DistrictsIcon';
import { MY_DISTRICT, MY_SCHOOL, PROVIDER_TYPE } from '../../../variables/constant';
import { Entity } from '../../../variables/types';
import { getMountainTimeZone } from '../../../utils/timezone';
import AddNewEntity from '../../../components/AddNewEntity';
import useSyncJobList from '../../DistrictSync/DistrictSyncTable/synclog-list-hook';
import { SyncStatus } from '../../../generated/graphql';
import ListWithTabs from '../../InfoTables';
import CalendarIcon from '../../../components/Icons/CalendarIcon';
import AppBreadcrumbs, { BreadcrumbItem } from '../../../components/AppBreadcrumbs';
import { districtsRoute } from '../../../variables/staticRoutes';
import useRole from '../../../hooks/useRole';
import useSchool from '../../Schools/School/school-hook';
const District = () => {
  const {
    district,
    loading,
    editDistrictPermission,
    id,
  } = useDistrict();

  const {
    school,
  } = useSchool();

  const { syncEnabled } = useSyncJobList();
  const { isSuperAdmin, isSchoolAdmin } = useRole();
  const breadcrumb: BreadcrumbItem[] = [
    districtsRoute,
    {
      label: district?.name ?? '',
      route: `${districtsRoute.route}/${district?.id}`,
    },
  ];
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      {isSuperAdmin && <AppBreadcrumbs breadcrumb={loading ? [] : breadcrumb} />}
      <ResourceDetailHeader
        title={district?.name ?? ''}
        addNewEntity={editDistrictPermission ? (
          <AddNewEntity
            entity={district?.source !== PROVIDER_TYPE.FLASHLIGHT ? Entity.CleverDistrict : Entity.District}
            districtId={district?.id}
            stateId={district?.state?.id}
          />
        ) : undefined
        }
        primaryBtnRedirectionLink={`/admin/create/district/${district?.id}`}
        syncNowRedirectionLink={district?.source !== PROVIDER_TYPE.FLASHLIGHT ? `/syncDistricts?districtId=${district?.id}` : undefined}
        syncNowEnabled={district?.is_disabled || syncEnabled || district?.sync_status === SyncStatus.InProgress}
        syncNowShow={!loading}
        PrimaryBtnIcon={PencilIcon}
        primaryBtnLabel={editDistrictPermission ? 'Edit' : undefined}
      />
      <Box my={2}>
        <StyledBoxWrapper width="30%">
          <ResourceDetail label={district?.state?.name || '-'} Icon={StateIcon} />
          <ResourceDetail label={`District ID: ${district?.district_id || '-'}`} Icon={DistrictsIcon} />
          {district?.source !== PROVIDER_TYPE.FLASHLIGHT &&
            <ResourceDetail label={`Clever ID: ${district?.source_id || '-'}`} Icon={DistrictsIcon} />
          }
          {district?.source !== PROVIDER_TYPE.FLASHLIGHT &&
            <ResourceDetail label={`Updated At: ${getMountainTimeZone(district?.last_synced_at, '-')}` } Icon={CalendarIcon} />
          }
        </StyledBoxWrapper>
        <ListWithTabs
          sectionType={'districts'}
          districtID={district?.id}
          isExternalSource={!loading && Boolean(district?.source !== PROVIDER_TYPE.FLASHLIGHT)}
          isMyDistrict={id === MY_DISTRICT}
          isMySchool={!(id !== MY_SCHOOL)}
          schoolID={isSchoolAdmin ? school?.id : undefined}
        />
      </Box>
      <Loader open={loading} />
    </Box>
  );
};

export default React.memo(District);
