import React, { useState } from 'react';
import Pagination from '@mui/material/Pagination';
import { Box } from '@mui/material';
import RecordCount from '../RecordCount';
import ListMenu from '../AppFilter/ListMenu';
import { PagesOptions, ROWS_PER_PAGE } from '../../variables/constant';


interface Props {
  page: number,
  totalCount: number, 
  onChange: (event: any, page: React.SetStateAction<number>) => void,
  disabled?: boolean,
  rowsPerPage: number,
  setRowsPerPage: Function,
}


const TablePagination = ({ page, onChange, totalCount, rowsPerPage, setRowsPerPage, disabled }: Props) => {

  const [defaultPage, setDefaultPage] = useState(Pagination.page);

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      paddingTop="20px"
      alignItems={'center'}
      gap={1}

    >
      <RecordCount
        pageNumber={page}
        recordLength={rowsPerPage}
        totalRecords={totalCount}
      />
      {totalCount > ROWS_PER_PAGE && <ListMenu
        menuTitle="Rows Per Page"
        hideMenuTitle
        selectedItem={rowsPerPage}
        onChange={(e: any) => { setRowsPerPage(+e.target.value); setDefaultPage(1); }}
        options={PagesOptions}
        keyAttribute="value"
        labelAttribute="label"
        width={'160px'}

      />}
      {totalCount > ROWS_PER_PAGE && <Box>
        <Pagination
          count={Math.ceil(totalCount / rowsPerPage) || 0}
          page={page}
          onChange={onChange}
          defaultPage={defaultPage}
          disabled={disabled}
        />
      </Box>}
    </Box>

  );
};
export default TablePagination;
