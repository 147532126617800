import React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Keyword, Maybe } from '../../generated/graphql';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Typography,
} from '@mui/material';
import { palette } from '../../theme/palette';
import useKeyword from './keyword-hook';
import SearchIcon from '../Icons/SearchIcon';

const filter = createFilterOptions<Keyword>();

interface Props {
  error?: boolean;
  className?: string;
  value?: Maybe<string>[];
  onChange?: (data: Maybe<string>[] | undefined) => void;
  disabled?: boolean;
}

const styles = {
  border: 'none',
  padding: 0,
  '& input.MuiInputBase-input.Mui-disabled': {
    backgroundColor: palette.customWhite.inputDisabled,
  },
  '& input.MuiOutlinedInput-root.MuiInputBase-multiline': {
    height: 'auto',
  },
};

const inputStyles = {
  '& .MuiInputBase-root': { height: 'auto' },
  width: '80%',
};

const CHARACTER_LIMIT = 500;

const AddKeywordTags = ({ className, onChange, value = [], disabled }: Props) => {
  const { keywords, keywordLoading, createTags, loading } = useKeyword();

  const [open, toggleOpen] = React.useState<boolean>(false);
  const [dialogValue, setDialogValue] = React.useState<string>('');

  const handleClose = () => {
    setDialogValue('');
    toggleOpen(false);
  };

  const handleSubmit = async () => {
    //string is broken into array of strings as per comma separated value provided,
    //trim of each keyword is handled by BE

    const response = await createTags(
      dialogValue
        ?.trim()
        .toLocaleLowerCase()
        .split(',')
        ?.filter((word) => word),
    );
    handleClose();
    if (!!response?.data) onChange?.([...new Set([...value, ...response.data?.addKeyword])]);
  };
  const onSelectChange = (_: any, words: any[]) => {
    const addKeywordText = words[words.length - 1]?.id?.toLocaleLowerCase();
    const filteredKeyword = keywords.find((text) => text?.keyword === addKeywordText || text?.id === addKeywordText)
    if (
      !filteredKeyword &&
      addKeywordText) {
      toggleOpen(true);
      setDialogValue(addKeywordText);
    } else {
      if (filteredKeyword) {
        const updatedWords = words?.map((word) => word?.id?.toLocaleLowerCase() !== addKeywordText ? word : filteredKeyword);
        onChange?.(updatedWords?.map((word: any) => word?.id?.toLocaleLowerCase()));
      }
      else { onChange?.(words?.map((word: any) => word?.id?.toLocaleLowerCase())); }
    }
  };

  return (
    <Box>
      <Autocomplete
        multiple
        className={className}
        freeSolo
        disableCloseOnSelect
        filterSelectedOptions
        options={keywords}
        getOptionLabel={(option: any) => `${option?.keyword ?? ''}`}
        isOptionEqualToValue={(option: Keyword, selected: Keyword) => option?.id === selected?.id}
        disabled={keywordLoading || disabled || loading}
        onChange={onSelectChange}
        value={keywords?.filter((word: any) => value?.includes(word?.id))}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== '') {
            filtered.push({
              id: params.inputValue,
              keyword: `Create Tag for "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={
                <Typography
                  sx={{
                    fontWeight: 800,
                    fontSize: '13px',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                  }}
                  component="span"
                >
                  {option?.keyword ?? ''}
                </Typography>
              }
              {...getTagProps({ index })}
              color={'primary'}
              style={{ height: '100%' }}
            />
          ))
        }
        style={{ width: 600, maxHeight: 500, overflowY: 'scroll' }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={params.InputProps.startAdornment ? 'Add More' : 'Example: animals'}
            sx={{ ...styles }}
            InputProps={{
              ...params.InputProps,
              style: { minHeight: '50px', maxHeight: '500px', height: 'auto', fontSize: '16px', overflowY: 'scroll' },
              startAdornment: (
                <>
                  <InputAdornment position="start" sx={{ marginLeft: 1, marginRight: 0.25 }}>
                    <SearchIcon />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a new keyword</DialogTitle>
        <DialogContent>
          <DialogContentText>Did we miss any keyword in our list? Please, add it!</DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={dialogValue}
            helperText={`Character limit is ${CHARACTER_LIMIT}`}
            onChange={(event) => setDialogValue(event.target.value)}
            variant="standard"
            multiline
            maxRows={3}
            sx={{
              ...inputStyles,
            }}
            inputProps={{
              maxLength: CHARACTER_LIMIT,
              style: { border: 'none' },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} variant="contained">
            Add
          </Button>
          <Button onClick={handleClose} variant="outlined" color={'error'}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddKeywordTags;
