import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import React, { useContext, useEffect, useState } from 'react';
import { ROWS_PER_PAGE } from '../../../variables/constant';
import {
  DistrictListSortType,
  OrderBy,
  StateFilterFragmentFragment,
  useDistrictsQuery,
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { DistrictSort } from '../../../variables/types';
import { SearchContext } from '../../../core/searchContext';


const useDistricts = () => {
  const pagination = usePagination();
  const { searchTerm } = useContext(SearchContext);
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, DistrictSort.Name));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Asc));
  const [selectedState, setStateValue] = useQueryParam('state', StringParam);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  useEffect(() => {
    pagination.setPage(1);
  }, [rowsPerPage]);
  const { data, loading } = useDistrictsQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: rowsPerPage,
      page: pagination.page,
      sort: sort as DistrictListSortType,
      orderBy: order as OrderBy,
      ...(searchTerm && searchTerm.length > 2 ? { searchByName: searchTerm } : {}),
      ...(selectedState ? { state_ids: [selectedState] } : {}),
      ...(searchTerm && searchTerm?.length > 2 ? { searchByName: searchTerm } : {}),
    },
  });

  const onSortChange = (sortBy: DistrictSort, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const districts = data?.districtList.nodes ?? [];
  const totalCount = data?.districtList.totalCount ?? 0;

  const isTableEmpty = !loading && districts?.length === 0;

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
  };

  const handleSort = (column: DistrictSort) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const onStateChange = (state: string | StateFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setStateValue(state as string);
  };

  return {
    loading,
    totalCount,
    districts,
    pagination,
    onSortChange,
    sort: sort as DistrictSort,
    order: order as OrderBy,
    isTableEmpty,
    handlePageChange,
    handleSort,
    selectedState,
    onStateChange,
    rowsPerPage,
    setRowsPerPage,
  };
};

export default useDistricts;
