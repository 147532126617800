import React from 'react';
import { Box, Typography } from '@mui/material';
import GradesTable from './GradesTable';
import Layout from '../../../components/Layout';
import useTeacherBenchmarksSubmissionList from '../Grade/grades-list-hook';
import TypeformEmbed from '../../../components/TypeformEmbed';
import { useImitationProvider } from '../../../core/imitationContext';

const Grade = () => {
  const {
    benchmarkSubmissionList,
    totalCount,
    loading,
    pagination,
    sort,
    order,
    handleSort,
    handleRedirection, handlePagination,
    selectedBenchmarkTypes, setSelectedBenchmarkTypes,
    selectedStatus, setSelectedStatus,
    handleApplyFilters,
    handleClearFilters,
    submissionQueueData,
    selectedGradedBy,
    setSelectedGradedBy,
    benchmarkSurveyTypeformData,
    onTypeformReady,
    onTypeformSubmit,
    getSurveyStatus,
    userId,
  } = useTeacherBenchmarksSubmissionList();

  const { getImitationUserType } = useImitationProvider();
  const isNotImitated = !getImitationUserType();

  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Typography variant="pageTitle">
          Grade
        </Typography>
        <Box display="flex" mt={2}>
          <GradesTable
            benchmarkSubmissionList={benchmarkSubmissionList}
            totalCount={totalCount}
            loading={loading}
            pagination={pagination}
            sort={sort}
            order={order}
            handleSort={handleSort}
            handleRedirection={handleRedirection}
            selectedBenchmarkTypes={selectedBenchmarkTypes}
            setSelectedBenchmarkTypes={setSelectedBenchmarkTypes}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            handleApplyFilters={handleApplyFilters}
            handleClearFilters={handleClearFilters}
            handlePagination={handlePagination}
            submissionQueueData={submissionQueueData}
            selectedGradedBy={selectedGradedBy}
            setSelectedGradedBy={setSelectedGradedBy}
          />
        </Box>
      </Box>
      {
        isNotImitated && getSurveyStatus() && benchmarkSurveyTypeformData?.show_typeform && <TypeformEmbed
          formId={benchmarkSurveyTypeformData?.typeform_form_id}
          typeformLayout='popover'
          onCloseHandler={onTypeformReady}
          onFormSubmittedHandler={onTypeformSubmit}
          hiddenButton={true}
          buttonText='Feedback Form'
          hiddenFields={{ user_id: userId }}
          delay={200}
        />
      }
    </Layout>
  );
};

export default Grade;
