import React from 'react';
import useCompletedAssignmentLearningGoals from './completed-assignment-learning-goals';
import StyledCard from '../../../../components/StyledCard';
import AppTooltip from '../../../../components/AppTooltip';
import { AdminDashboards } from '../../../../variables/constant';
import { Box, SxProps, Typography } from '@mui/material';
import { palette } from '../../../../theme/palette';
import {
  LearningGoalsCompletedPTsMostRecentGoalsPerMonth,
} from '../../../../generated/graphql';
import CustomTooltip from './CustomToolTip';
import { formatSessionDate } from '../../../../components/AdminGraphs/CommonFunctions';
import SimpleBarChart from '../../../../components/AdminGraphs/SimpleBarChart';

interface Props{
  enableTooltip?: boolean
  alignTitle?: boolean
}

const CompletedAssignmentLearningGoalsWidget = ({ enableTooltip = true, alignTitle = true }:Props) => {

  const {
    assignmentLearningGoalsCompletedLoading, title,
    completeData,
    currStartDate,
    currEndDate,
    hasData,
  } = useCompletedAssignmentLearningGoals();

  const processData = (data: LearningGoalsCompletedPTsMostRecentGoalsPerMonth[]) => {
    return data?.map((eventData:LearningGoalsCompletedPTsMostRecentGoalsPerMonth) => ({
      ...eventData,
      remainingUnits: Math.abs(eventData?.unique_student_completed_pts_count! - eventData?.most_recent_goals_count!),
    }));
  };
  const processedData = completeData && processData(completeData);
  return (
    <Box id={AdminDashboards.learningGoals.CompletedAssignmentsGoalsWidget.name}>
      <StyledCard
        title={title}
        columnSetting
        isToolTip={enableTooltip}
        titleAlignment={alignTitle ? 'left' : 'center'}
        toolTipComponent={<AppTooltip toolTipText={AdminDashboards.learningGoals.CompletedAssignmentsGoalsWidget.tooltipText}/>}
        contentAlignment
      >
        {
          !assignmentLearningGoalsCompletedLoading && <Typography component='p' variant='secondaryLabel' sx={{ lineHeight: '31px', color: palette.customBlack.dimmedBlack } as SxProps}>
            {formatSessionDate(currStartDate)} - {formatSessionDate(currEndDate)}
          </Typography>
        }
        <Box width='100%'>
          <SimpleBarChart
            barData={processedData}
            loading={assignmentLearningGoalsCompletedLoading}
            primaryLegend='Submissions'
            secondaryLegend='Goals'
            showData={hasData! > 0}
            averageLine='average_no_of_most_recent_goals_count'
            blueBar='most_recent_goals_count'
            greyBar='unique_student_completed_pts_count'
            toolTipComponent={<CustomTooltip />}
          />
        </Box>
      </StyledCard>
    </Box>
  );
};

export default CompletedAssignmentLearningGoalsWidget;
