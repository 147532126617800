import React, { useContext, useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import { Stack } from '@mui/material';
import { StyledIconButton } from '../../Assignments/Assignments.styled';
import DownloadFileIcon from '../../../components/Icons/DownloadFileIcon';
import PrintIcon from '../../../components/Icons/PrintIcon';
import { NetworkStatus } from '@apollo/client';
import { useDistrictWideGradeDataQuery, useDistrictWideSchoolDataQuery } from '../../../generated/graphql';
import { DistrictWideComparisonsContext, DistrictWideQueryInput } from './district-wide-comparisons-hook';
import { DistrictWideComparisonsTabs, NotifierType } from '../../../variables/types';
import { districtWideSchoolsPdfTable } from './Schools/pdf';
import { districtWideGradesPdfTable } from './Grades/pdf';
import { getPdfTextWidth } from '../../../utils/pdf';
import { openSnackbar } from '../../../components/Notifier';
import { PDF_GENERATING_ERROR } from '../../../variables/constant';
import { sentryErrorLog } from '../../../utils/sentry';
import capitalize from '../../../utils/capitalize';

interface ExportToPdfProps {
  areWidgetsLoading: boolean,
  tabKey: string,
}

interface GeneratePdfProps {
  print: boolean,
}

const ExportToPdf = ({
  areWidgetsLoading,
  tabKey,
}: ExportToPdfProps) => {
  const [dashboardPrint, setdashboardPrint] = useState(false);
  const [dashboardData, setDashboardData] = useState<any>([]);
  const {
    isSchoolAdmin, isDistrictAdmin,
    mySchoolData, myDistrictData,
    schoolsDataCount, schoolsDataQueryInput,
    gradesDataQueryInput,
  } = useContext(DistrictWideComparisonsContext);
  let queryInput: DistrictWideQueryInput = {
    ...schoolsDataQueryInput,
    limit: schoolsDataCount,
    page: 1,
    ...(isSchoolAdmin ? { school_id: mySchoolData?.id } : {}),
    ...(isDistrictAdmin ? { district_id: myDistrictData?.id } : {}),
  };
  if (tabKey === DistrictWideComparisonsTabs.Grades) {
    queryInput = {
      ...gradesDataQueryInput,
      ...(isSchoolAdmin ? { school_id: mySchoolData?.id } : {}),
      ...(isDistrictAdmin ? { district_id: myDistrictData?.id } : {}),
    };
  }
  const {
    setLoading,
  } = useContext(DistrictWideComparisonsContext);
  // export the respective dsashboard Tab into the PDF to be generated.
  const {
    loading: districtWideSchoolsDataLoading, networkStatus: districtWideSchoolNetworkStatus,
    data: districtWideSchoolsData, refetch: districtWideSchoolsDataRefetch,
  } = useDistrictWideSchoolDataQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: queryInput,
    skip: (tabKey !== DistrictWideComparisonsTabs.Schools) || (!queryInput.school_id && !queryInput.district_id),
  });
  const {
    loading: districtWideGradeDataLoading, networkStatus: districtWideGradeNetworkStatus,
    data: districtWideGradeData, refetch: districtWideGradeDataRefetch,
  } = useDistrictWideGradeDataQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { 'input': queryInput },
    skip: (tabKey !== DistrictWideComparisonsTabs.Grades) || (!queryInput.school_id && !queryInput.district_id),
  });
  // Refetch the whole Dashboard Table Data
  const fetchDashboardData = async () => {
    try {
      if (tabKey === DistrictWideComparisonsTabs.Schools) {
        await districtWideSchoolsDataRefetch({
          ...queryInput,
        });
        setDashboardData(districtWideSchoolsData?.districtWideSchoolData?.districtWideSchoolDetails ?? []);
      } else if (tabKey === DistrictWideComparisonsTabs.Grades) {
        await districtWideGradeDataRefetch({
          'input': {
            ...queryInput,
          },
        });
        setDashboardData(districtWideGradeData?.districtWideGradeInfo ?? []);
      }
    } catch (e) {
      sentryErrorLog(e, [
        { label: 'pdfDataErr', value: `Error fetching data for PDF for District-wide Comparisons > ${tabKey}` },
      ]);
      openSnackbar({ message: PDF_GENERATING_ERROR }, NotifierType.Error);
    }
  };
  // Generate the PDF
  const generatePdf = async ({
    print = false,
  }: GeneratePdfProps) => {
    let pdfName = '';
    const pdfPadding = 10;
    const pdfMargin = 20;
    const autoTableStyles = {
      margin: {
        left: pdfMargin,
        right: pdfMargin,
      },
      header: {
        fillColor: [42, 42, 139], // Set background color for the header row
        textColor: [255, 255, 255],
        fontSize:8,
        fontStyle: 'bold',
        0: {
          halign: 'left',
        },
      },
      rows: {
        halign: 'center',
        fontSize: 8,
      },
      columns: {
        0: {
          halign: 'left',
        },
      },
    };
    let posX = pdfMargin;
    let posY = pdfPadding + 5;
    const pdfDoc = new jsPDF('p', 'px');
    if (print) {
      pdfDoc.autoPrint({ variant: 'non-conform' });
    }
    // Add a header with Date for referrence.
    pdfDoc.setFontSize(8);
    let headerText = isDistrictAdmin ? myDistrictData?.name : mySchoolData?.name;
    pdfDoc.text(`${headerText}`, posX, posY);
    const date = new Date();
    const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    pdfDoc.text(dateString, pdfDoc.internal.pageSize.width - pdfMargin, posY, { align: 'right' });
    posY += 5;
    pdfDoc.line(posX, posY, pdfDoc.internal.pageSize.width - pdfMargin, posY);
    posY += 2 * pdfPadding;
    pdfDoc.setFontSize(18);
    pdfDoc.text('District-Wide Comparisons', posX, posY);
    let tPosX = posX + getPdfTextWidth('District-Wide Comparisons', pdfDoc, 18) + pdfPadding;
    pdfDoc.setFontSize(14);
    pdfDoc.text(`|  ${capitalize(tabKey)}`, tPosX, posY);
    posY += pdfPadding;
    // export the respective dsashboard into the PDF to be generated.
    if (tabKey === DistrictWideComparisonsTabs.Schools) {
      districtWideSchoolsPdfTable({ pdfDoc, posY, styles: autoTableStyles, schoolsData: dashboardData });
    } else if (tabKey === DistrictWideComparisonsTabs.Grades) {
      districtWideGradesPdfTable({ pdfDoc, posY, styles: autoTableStyles, gradesData: dashboardData });
    }
    //
    pdfName += `${headerText}-District-Wide Comparisons-${tabKey}-${dateString}`;
    pdfDoc.setProperties({
      title: pdfName,
    });
    if (print) {
      pdfDoc.autoPrint({ variant: 'non-conform' });
      window.open(pdfDoc.output('bloburl'), '_blank');
    } else {
      pdfDoc.save(pdfName);
    }
    // reset the dashboard Data fetched and flags
    setDashboardData([]);
    setdashboardPrint(false);
  };
  useEffect(() => {
    if (!dashboardData.length) return;
    try {
      generatePdf({
        print: dashboardPrint,
      });
    } catch (e) {
      sentryErrorLog(e, [
        { label: 'pdfErr', value: `Error Generating PDF for District-wide Comparisons > ${tabKey}` },
      ]);
      openSnackbar({ message: PDF_GENERATING_ERROR }, NotifierType.Error);
    }
  }, [dashboardData]);
  useEffect(() => {
    if ([districtWideSchoolNetworkStatus, districtWideGradeNetworkStatus].includes(NetworkStatus.refetch)){
      setLoading?.(true);
    }
    setLoading?.(districtWideSchoolsDataLoading || districtWideGradeDataLoading);
  }, [
    districtWideSchoolsDataLoading, districtWideSchoolNetworkStatus,
    districtWideGradeDataLoading, districtWideGradeNetworkStatus,
  ]);
  return (
    <>
      <Stack direction="row" justifyContent="end">
        <StyledIconButton
          color="primary"
          onClick={() => {
            fetchDashboardData();
            setdashboardPrint(false);
          }}
          className="print-ignore"
          disabled={areWidgetsLoading}
        >
          <DownloadFileIcon />
        </StyledIconButton>
        <StyledIconButton
          color="primary"
          onClick={() => {
            fetchDashboardData();
            setdashboardPrint(true);
          }}
          className="print-ignore"
          disabled={areWidgetsLoading}
        >
          <PrintIcon />
        </StyledIconButton>
      </Stack>
    </>
  );
};

export default ExportToPdf;