import { useHistory } from 'react-router-dom';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

const usePagination = (param?: string) => {
  const history = useHistory();
  const [page, setPageParam] = useQueryParam(param || 'page', withDefault(NumberParam, 1));

  const setPage = (nextPage: number) => {
    // while going to next or previous page user might wanna get back to previous page by pressing back button
    // allowing to add url to stack by changing page param
    setPageParam(nextPage);
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set(param || 'page', nextPage.toString());
    window.history.replaceState({}, '', currentUrl.toString());
  };
  const resetPage = () => {
    // initially or on reset the page number,
    // user should be able to redirect by the back button as there will not be any new url pushed to history stack created by this method
    setPageParam(1);
    const updatedQueryUrl = param ? `?${param}=1` : '?page=1';
    history.replace(updatedQueryUrl);
  };

  return {
    page,
    setPage,
    resetPage,
  };
};

export default usePagination;
