import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  ANCHOR_GRADE_1_2,
  ANCHOR_GRADE_3_5,
  ANCHOR_GRADE_6_8,
  ANCHOR_GRADE_9_12,
  ANCHOR_GRADE_PK_K,
  CreateBenchmarkSteps,
} from '../../../variables/constant';
import getErrorMessage from '../../../utils/getErrorMessage';
import { openSnackbar } from '../../../components/Notifier';
import useRedirect from '../../../hooks/useRedirect';
import gradeSort from '../../Assignments/CreateAssignment/grade-sort';
import { useFormContext } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import {
  AssignmentInput,
  GetRouteDataProps,
  RecordingTracker,
  RouteDataProp,
  RouteParamProp,
  StaticResourceImgSelectedInitial,
} from '../../Assignments/CreateAssignment/create-assignment-hook';
import {
  BenchmarkGradedBy,
  BenchmarkType,
  StaticResource,
  StaticResourceType,
  useCreateBenchmarkMutation,
  useGetAcademicSessionQuery,
  useStaticResourcesQuery,
  useSubjectAreasQuery,
} from '../../../generated/graphql';
import { FormStep, NotifierType } from '../../../variables/types';
import { cleanDate, toUTC, toUTCEOD } from '../../../utils/dateFormat';

export interface DistrictStudentsQueryInput {
  district_ids: string[]
  grade_ids?: string[]
  school_ids?: string[]
}

type BenchmarkAssignInput = Omit<AssignmentInput, 'section_id'>;

export interface BenchmarkInput extends BenchmarkAssignInput, DistrictStudentsQueryInput{
  graded_by: BenchmarkGradedBy | string
  benchmark_type: BenchmarkType | string
}


export const BenchmarkInputInitial: BenchmarkInput = {
  back_navigation_allowed: true,
  close_at: new Date(),
  is_common_prompt: true,
  resource_id: '',
  speaking_prompt: '',
  speaking_prompt_file_name: '',
  speaking_prompt_file_type: '',
  speaking_prompt_url: '',
  start_at: new Date(),
  title: '',
  writing_prompt: '',
  writing_prompt_file_name: '',
  writing_prompt_file_type: '',
  writing_prompt_url: '',
  district_ids: [],
  grade_ids: [],
  school_ids: [],
  graded_by: BenchmarkGradedBy.Teacher,
  benchmark_type: '',
};

export interface BenchmarkContextValues{
  loading?: boolean,
  setLoading?: Function,
  routeData?: RouteDataProp,
  // Assignment Data trackers
  benchmarkInput?: BenchmarkInput,
  setBenchmarkInput?: Function,
  staticResourceImgSelected?: StaticResource,
  setStaticResourceImgSelected?: Function,
  speakingPromptFile?: File | null,
  setSpeakingPromptFileBM?: Function,
  writingPromptFile?: File | null,
  setWritingPromptFileBM?: Function,
  isPastBenchmark?: boolean,
  // Assignment Form Filter trackers
  selectedGrade?: string[] | undefined,
  setSelectedGrade?: Function,
  searchTerm?: string,
  setSearchTerm?: Function,
  selectedSubjects?: string[] | undefined,
  setSubjectAreas?: Function,
  selectedTaskTypes?:string[] | undefined,
  setTaskTypes: Function,
  // Form Behaviour trackers
  recordingTracker?: RecordingTracker[],
  setRecordingTracker?: Function,
}

export const GetRouteData = ({ id, step, pathname }: GetRouteDataProps): RouteDataProp => {
  const action: string = pathname?.includes('/edit') ? 'edit' : 'create';
  const rootPath: string = `/benchmarks/${!id ? '' : (id + '/')}${action}`;
  let currentStep = CreateBenchmarkSteps.find(steps => steps.name === step)?.step ?? 1;
  return {
    id,
    step,
    action,
    rootPath,
    currentStep,
  };
};

export const CreateBenchmarkInit = () => {
  const location = useLocation();
  const { id, step } = useParams<RouteParamProp>();
  const routeData: RouteDataProp = GetRouteData({ id, step, pathname: location.pathname });
  const [loading, setLoading] = useState(false);
  const [benchmarkInput, setBenchmarkInput] = useState(BenchmarkInputInitial);
  const [staticResourceImgSelected, setStaticResourceImgSelected] = useState(StaticResourceImgSelectedInitial);
  const [speakingPromptFile, setSpeakingPromptFileBM] = useState<File | null>(null);
  const [writingPromptFile, setWritingPromptFileBM] = useState<File | null>(null);
  const [benchmarkFetched, setBenchmarkFetched] = useState<any>('');
  const [isPastBenchmark, setIsPastBenchmark] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [selectedSubjects, setSubjectAreas] = useState([]);
  const [selectedTaskTypes, setTaskTypes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [recordingTracker, setRecordingTracker] = useState<RecordingTracker[]>([]);
  return {
    routeData,
    loading, setLoading,
    benchmarkInput, setBenchmarkInput,
    staticResourceImgSelected, setStaticResourceImgSelected,
    speakingPromptFile, setSpeakingPromptFileBM,
    writingPromptFile, setWritingPromptFileBM,
    benchmarkFetched, setBenchmarkFetched,
    isPastBenchmark, setIsPastBenchmark,
    selectedGrade, setSelectedGrade,
    searchTerm, setSearchTerm,
    recordingTracker, setRecordingTracker,
    selectedSubjects, setSubjectAreas,
    selectedTaskTypes, setTaskTypes,
  };
};

export const CreateBenchmarkContext = createContext<BenchmarkContextValues>({
  selectedSubjects: [],
  selectedTaskTypes: [],
  setSubjectAreas: () =>{},
  setTaskTypes: () => {},
  benchmarkInput: BenchmarkInputInitial,
  staticResourceImgSelected: StaticResourceImgSelectedInitial,
});

export const useCreateBenchmark = () => {

  const { navigateTo } = useRedirect();
  const {
    routeData,
    loading, setLoading,
    benchmarkInput, setBenchmarkInput, isPastBenchmark,
    staticResourceImgSelected, setStaticResourceImgSelected,
    speakingPromptFile, setSpeakingPromptFileBM,
    writingPromptFile, setWritingPromptFileBM,
    selectedGrade, setSelectedGrade,
    searchTerm, setSearchTerm,
    recordingTracker, setRecordingTracker,
    selectedSubjects, setSubjectAreas,
    selectedTaskTypes, setTaskTypes,
  } = useContext(CreateBenchmarkContext);

  const {
    reset,
  } = useFormContext();

  const [titleState, setTitleState] = useState(benchmarkInput?.title ?? '');
  const [speakingPrompt, setSpeakingPrompt] = useState(benchmarkInput?.speaking_prompt ?? '');
  const [writingPrompt, setWritingPrompt] = useState(benchmarkInput?.writing_prompt ?? '');

  const [districts, setDistrictIDs] = useState(benchmarkInput?.district_ids ?? []);
  const [grades, setGradeIDs] = useState(benchmarkInput?.grade_ids ?? []);
  const [schools, setSchoolIDs] = useState(benchmarkInput?.school_ids ?? []);

  const gradesForImageFilter = [ANCHOR_GRADE_PK_K, ANCHOR_GRADE_1_2, ANCHOR_GRADE_3_5, ANCHOR_GRADE_6_8, ANCHOR_GRADE_9_12];

  // fetch static Resources only for step 2
  const { data: staticResourcesData, loading: imagesLoading } = useStaticResourcesQuery({
    fetchPolicy: 'network-only',
    variables:  {
      resourceType: StaticResourceType.Benchmark,
      ...(selectedGrade?.length ? { grade: selectedGrade } : {}),
      ...(selectedSubjects?.length ? { subjectAreas: selectedSubjects } : {}),
      ...(selectedTaskTypes?.length ? { taskTypes: selectedTaskTypes } : {}),
      ...(searchTerm && searchTerm?.length > 2 ? { keywords: searchTerm?.trim().split(' ') } : {}),
    },
    skip: routeData?.currentStep !== 2,
  });

  const { data: academicSession, loading: academicSessionLoading } = useGetAcademicSessionQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: routeData?.currentStep !== 1,
  });

  const setGrades = gradeSort(staticResourcesData);
  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event!.target.value === '') {
      setSearchTerm?.('');
    } else {
      setSearchTerm?.(event!.target.value);
    }
  };

  const [createBenchmark, { loading: createBenchmarkInProgress }] = useCreateBenchmarkMutation();
  const prepBenchmarkPayload = (benchmark: BenchmarkInput): BenchmarkInput => {
    const payload = benchmark;
    // @ts-ignore
    payload.start_at = toUTC(cleanDate(benchmark.start_at) as Date);
    // @ts-ignore
    payload.close_at = toUTCEOD(cleanDate(benchmark.close_at) as Date);
    if (benchmark.speaking_prompt_url) {
      payload.speaking_prompt_url = benchmark.speaking_prompt_url;
      payload.speaking_prompt_file_type = benchmark.speaking_prompt_file_type;
      payload.speaking_prompt_file_name = benchmark.speaking_prompt_file_name;
    }
    if (benchmark.is_common_prompt) {
      payload.writing_prompt_url = '';
      payload.writing_prompt_file_type = '';
      payload.writing_prompt_file_name = '';
      payload.writing_prompt = '';
    } else {
      payload.writing_prompt_url = benchmark.writing_prompt_url;
      payload.writing_prompt_file_type = benchmark.writing_prompt_file_type;
      payload.writing_prompt_file_name = benchmark.writing_prompt_file_name;
    }
    return payload;
  };
  const create = async (benchmark: BenchmarkInput) => {
    try {
      const benchmarkPayload: BenchmarkInput = prepBenchmarkPayload(benchmark);
      await createBenchmark({
        variables: {
          // @ts-ignore
          input: { ...benchmarkPayload },
        },
      });
      navigateTo('/benchmarks');
      openSnackbar({
        message: 'Benchmark created successfully',
      }, NotifierType.Success);
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  const updateBenchmarkContext = (benchData: Object) => {
    setBenchmarkInput?.((prevBenchmarkInput: BenchmarkInput) => ({
      ...prevBenchmarkInput,
      ...benchData,
    }));
  };

  const rootPath = routeData?.rootPath;

  const backToBenchmarks = () => {
    navigateTo('/benchmarks');
  };

  const handlePrev = (inputData?: any) => {
    if (inputData) updateBenchmarkContext?.({
      ...inputData,
      title: titleState,
      speaking_prompt: speakingPrompt,
      writing_prompt: writingPrompt,
    });
    let current = routeData?.currentStep ?? 4;
    let prevStep = CreateBenchmarkSteps?.find((step:FormStep) => step.step === current - 1)?.name;
    navigateTo(`${rootPath}/${prevStep}`);
  };

  const handleNext = () => {
    let current = routeData?.currentStep ?? 1;
    let nextStep = CreateBenchmarkSteps?.find((step:FormStep) => step.step === current + 1)?.name;
    navigateTo(`${rootPath}/${nextStep}`);
  };

  const submitStep = async (formData: Object) => {
    await updateBenchmarkContext?.({ ...formData, title: titleState, speaking_prompt: speakingPrompt, writing_prompt: writingPrompt });
    handleNext();
  };

  useEffect(() => {
    reset(benchmarkInput);
  }, [benchmarkInput]);

  const { data: subjectAreas, loading: subjectsLoading } = useSubjectAreasQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: routeData?.currentStep !== 2,
  });

  useEffect(() => {
    setLoading?.(subjectsLoading || imagesLoading || createBenchmarkInProgress || academicSessionLoading);
  }, [subjectsLoading, imagesLoading, createBenchmarkInProgress, academicSessionLoading]);


  const resetFilters = () => {
    setSelectedGrade?.([]);
    setTaskTypes([]);
    setSearchTerm?.('');
    setSubjectAreas?.([]);
  };

  return {
    routeData,
    loading, setLoading,
    benchmarkInput, setBenchmarkInput, isPastBenchmark,
    staticResourceImgSelected, setStaticResourceImgSelected,
    speakingPromptFile, setSpeakingPromptFileBM,
    writingPromptFile, setWritingPromptFileBM,
    selectedGrade, setSelectedGrade,
    searchTerm, setSearchTerm,
    recordingTracker, setRecordingTracker,
    gradesForImageFilter,
    setGrades,
    onSearch,
    createBenchmark: create,
    updateBenchmarkContext,
    handlePrev,
    handleNext,
    submitStep,
    backToBenchmarks,
    subjectAreas: subjectAreas?.subjectArea,
    subjectsLoading, setSubjectAreas, selectedSubjects,
    selectedTaskTypes, setTaskTypes, resetFilters,
    titleState, setTitleState,
    speakingPrompt, setSpeakingPrompt,
    writingPrompt, setWritingPrompt,
    districts, setDistrictIDs,
    grades, setGradeIDs,
    schools, setSchoolIDs,
    academicSessionEndDate: academicSession?.getAcademicSession.session_end_date,
  };
};

export default useCreateBenchmark;
