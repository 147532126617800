import React from 'react';
import { Autocomplete, Box, Checkbox, CheckboxProps, InputAdornment } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SchoolSharpIcon from '@mui/icons-material/SchoolSharp';
import { palette } from '../../theme/palette';
import { svgStyles } from '../Common/Common.styled';
import { AutoCompleteTextField } from '../Autocomplete/Autocomplete.styled';
import { Maybe, PredefinedGrades, useGetAllPredefinedGradesQuery } from '../../generated/graphql';

interface Props {
  limitTags?: number;
  textInputPlaceholder?: string;
  value?: Maybe<string>[];
  onChange?: (data: PredefinedGrades[] | string[] | null, oldValue: PredefinedGrades[] | null) => void;
  onSelectedGradesChange?: (selectedGrades: PredefinedGrades[]) => void;
  gradesToEnable: Maybe<string>[];
  disabled?: boolean;
  placeholder?: string;
  idSelectOnly?: boolean;
  className?: string;
  noOptionPlaceholder?: string;
  districtId?: string;
  schoolIds?: string | string[];
  updateLoader?: Function;
}

const styles = {
  border: '0px',
  minHeight: '48px',
  height: 'auto',
  '& input.MuiInputBase-input.Mui-disabled': {
    backgroundColor: palette.customWhite.inputDisabled,
  },
};

/**
 * SelectGrade: Allow's user to select grade from a autocomplete dropdown
 * @param Props
 * @returns Autocomplete dropdown to select grade
 */
const SelectGradeMultiSelect = ({
  limitTags,
  textInputPlaceholder,
  value,
  // onChange,
  onSelectedGradesChange,
  gradesToEnable,
  disabled = false,
  placeholder,
  className,
  noOptionPlaceholder,
  districtId,
  schoolIds,
  updateLoader,
}: Props) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const reqQuery: any = {};
  if (districtId) reqQuery.districtID = [districtId];
  if (schoolIds && schoolIds.length > 0) reqQuery.schoolIDs = schoolIds;
  const { data: preDefinedGrades, loading: predefinedGradesLoading } = useGetAllPredefinedGradesQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...reqQuery,
    },
  });
  updateLoader?.(predefinedGradesLoading);
  var preDefinedGradesSorted: PredefinedGrades[] | undefined = preDefinedGrades?.allPredefinedGrades?.sort((grade1, grade2) => {
    var x = grade1.position;
    var y = grade2.position;
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
  const onSelectChange = (_: any, gradesList: PredefinedGrades[]) => {
    onSelectedGradesChange?.(gradesList);
  };
  const gradeOptions: PredefinedGrades[] = preDefinedGradesSorted?.filter((grade: PredefinedGrades) => gradesToEnable?.includes(grade?.id)) ?? [];
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      limitTags={limitTags}
      className={className}
      options={gradesToEnable && gradesToEnable.length ? gradeOptions : preDefinedGradesSorted || []}
      getOptionLabel={(option: any) => option.grade ?? option}
      isOptionEqualToValue={(option: any, selected: any) => option?.grade === selected?.grade}
      onChange={onSelectChange}
      value={preDefinedGradesSorted?.filter((grade: PredefinedGrades) => value?.includes(grade?.id)) || []}
      disabled={disabled || predefinedGradesLoading}
      noOptionsText={noOptionPlaceholder || 'No options'}
      renderInput={(params) => (
        <>
          <AutoCompleteTextField
            {...params}
            sx={{ ...styles }}
            placeholder={params.InputProps.startAdornment ? undefined : (textInputPlaceholder ? textInputPlaceholder : placeholder)}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { height: 'auto', maxHeight: '500px', fontSize: '16px' },
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <SchoolSharpIcon
                      fontSize="medium"
                      color="primary"
                      sx={{ ...svgStyles.icon }}
                    />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        </>
      )}
      renderOption={(props, option: any, { selected }) => (
        <Box component="li" {...props}>
          <Checkbox
            color={'primary' as CheckboxProps['color']}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            sx={{ mr: '8px' }}
          />
          {`${option?.grade ?? ''}`}
        </Box>
      )}
    />
  );
};

export default React.memo(SelectGradeMultiSelect);
