import React, { useEffect, useState } from 'react';
import {
  BenchmarkAssignToSortType,
  OrderBy,
  useGetBenchmarkStudentsQuery, useGetTotalBenchmarkStudentsQuery,
} from '../../../generated/graphql';
import { PAGE_SIZE, ROWS_PER_PAGE } from '../../../variables/constant';
import usePagination from '../../../utils/usePagination';

interface Props {
  districts : string | string[],
  grades? : string | string[] | undefined,
  schools? : string | string[] | undefined,
  currentStep: number,
}

const useStudentsCountFilter = ({ districts, grades, schools, currentStep }:Props) => {
  const pagination = usePagination();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  useEffect(() => { pagination.setPage(1); }, [rowsPerPage]);
  const [queryInput, setQueryInput] = useState({
    limit: rowsPerPage,
    page: pagination.page,
    sort: BenchmarkAssignToSortType.DistrictName,
    orderBy: OrderBy.Asc,
  });

  const { data: benchmarkStudents, loading: dataLoading } = useGetBenchmarkStudentsQuery({
    fetchPolicy: 'network-only',
    variables: {
      districtIds: districts || [],
      ...(grades ? { gradeIds: grades } : {}),
      ...(schools ? { schoolIds: schools } : {}),
      ...queryInput,
    },
    skip: currentStep !== 4,
  });

  const { data : studentsCount, loading: studentCountLoading } = useGetTotalBenchmarkStudentsQuery({
    fetchPolicy: 'network-only',
    variables: {
      districtIds: districts || [],
      ...(grades ? { gradeIds: grades } : {}),
      ...(schools ? { schoolIds: schools } : {}),
    },
    skip: !districts?.length && currentStep !== 1,
  });

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
    setQueryInput({
      limit: queryInput.limit,
      page: value as number,
      sort: queryInput.sort,
      orderBy: queryInput.orderBy,
    });
  };

  const onSortChange = (sortBy: BenchmarkAssignToSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    setQueryInput({
      limit: PAGE_SIZE,
      page: 1,
      sort: sortBy,
      orderBy: orderBy,
    });
  };

  const handleSort = (column: BenchmarkAssignToSortType) => () => {
    if (column === queryInput?.sort) {
      onSortChange(column, queryInput.orderBy === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  return {
    studentCountLoading: studentCountLoading || dataLoading,
    benchmarkStudents: benchmarkStudents?.benchmarkStudentCountByFilters?.nodes,
    studentsCount: studentsCount?.totalBenchmarkStudentCountByFilters ?? 0,
    totalCount: benchmarkStudents?.benchmarkStudentCountByFilters?.node_count ?? 0,
    pagination,
    handlePageChange,
    sort: queryInput?.sort,
    handleSort,
    order: queryInput?.orderBy,
    rowsPerPage, setRowsPerPage,
  };
};

export default useStudentsCountFilter;
