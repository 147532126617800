import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { LocalizationProvider }  from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PrivateRoute from '../core/privateRoute';
import Login from './Login/DistrictLogin';
import Students from './Students';
import Assignments from './Assignments';
import SubmitTask from './SubmitTask';
import ScoreTask from './ScoreTask';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import useUserInfo from '../utils/useUserInfo';
import { refreshAuthentication } from '../core/apolloClient';
import Backdrop from '../components/Backdrop';
import useFeature from '../hooks/useFeature';
import Credentials from './Login/Credentials';
import Districts from './Districts';
import Settings from './Settings';
import Teachers from './Teachers';
import Schools from './Schools';
import Classes from './Classes';
import CreateResource from './CreateResource';
import Admins from './Admins';
import AdminStudents from './AdminStudents';
import { useImitationProvider } from '../core/imitationContext';
import TeacherToolBox from './TeacherToolBox';
import StudentBackPack from './StudentBackPack';
import StudentsProgress from './StudentBackPack/StudentProgress';
import Scores from './StudentBackPack/StudentProgress/Scores';
import Analytics from './Analytics';
import Groups from './Groups';
import Dashboard from './Dashboard';
import { SearchContext } from '../core/searchContext';
import useSearch from '../hooks/useSearch';
import DistrictSync from './DistrictSync';
import AdminAnalytics from './AdminAnalytics';
import useRole from '../hooks/useRole';
import RoutingPaths from './RoutingPath';
import AdminBenchmarks from './AdminBenchmarks';
import TeacherBenchmarks from './TeacherBenchmarks';
import ScoreTaskBenchmark from './ScoreTaskBenchmark';
import { BreadcrumbProvider } from '../core/breadcrumbContext';

const AppRouter = () => {
  const [isLoadingUserInfo] = useUserInfo();
  const { imitationUserId } = useImitationProvider();
  const [isLoading, setIsLoading] = useState(true);
  const { isEnabled: isPasswordFlowEnable } = useFeature('PASSWORD_FLOW');
  const { isSchoolAdmin, isDistrictAdmin } = useRole();
  const searchState = useSearch();

  const checkAuthentication = useCallback(async () => {
    await refreshAuthentication(imitationUserId);
    setIsLoading(false);
  }, [imitationUserId]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  if (isLoading || isLoadingUserInfo) {
    return <Backdrop open />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route path={[RoutingPaths.LogIn]} exact component={Login} />
            <Route
              path={isPasswordFlowEnable ? [RoutingPaths.LogInPasswordEnable, RoutingPaths.LogInDistrictId] : RoutingPaths.LogInDistrictId}
              exact
              component={Credentials}
            />
            <Route path={RoutingPaths.ForgotPassword} exact component={ForgotPassword} />
            {isPasswordFlowEnable && <Route path={RoutingPaths.ForgotPassword} component={ForgotPassword} />}
            <SearchContext.Provider value={searchState}>
              <BreadcrumbProvider>
                <PrivateRoute path={RoutingPaths.Root}>
                  <Route path={RoutingPaths.Root} exact>
                    {(!isSchoolAdmin || !isDistrictAdmin) && <Redirect to={RoutingPaths.Grade} />}
                    {(isSchoolAdmin || isDistrictAdmin) && <Redirect to={RoutingPaths.AdminAnalytics} />}
                  </Route>
                  <Route path={[RoutingPaths.SectionAnalytics, RoutingPaths.Analytics]} component={Analytics} />
                  <Route path={RoutingPaths.Grade} component={Dashboard} />
                  <Route path={RoutingPaths.Students} component={Students} />
                  <Route path={RoutingPaths.Assignments} component={Assignments} />
                  {isPasswordFlowEnable && <Route path={RoutingPaths.ResetPassword} component={ResetPassword} />}
                  <Route path={RoutingPaths.Districts} component={Districts} />
                  <Route path={RoutingPaths.Teachers} component={Teachers} />
                  <Route path={RoutingPaths.Schools} component={Schools} />
                  <Route path={RoutingPaths.Classes} component={Classes} />
                  <Route path={RoutingPaths.AdminStudents} component={AdminStudents} />
                  <Route path={RoutingPaths.Settings} component={Settings} />
                  <Route path={RoutingPaths.Admins} component={Admins} />
                  <Route path={RoutingPaths.CreateAdmin} component={CreateResource} />
                  <Route path={RoutingPaths.AdminAnalytics} component={AdminAnalytics} />
                  <Route path={RoutingPaths.SubmitTask} component={SubmitTask} />
                  <Route path={RoutingPaths.ScoreTask} component={ScoreTask} />
                  <Route path={RoutingPaths.ToolBox} component={TeacherToolBox} />
                  <Route path={RoutingPaths.BackPack} component={StudentBackPack} />
                  <Route path={RoutingPaths.Scores} component={Scores} />
                  <Route path={RoutingPaths.StudentProgress} exact component={StudentsProgress} />
                  <Route path={RoutingPaths.Groups} component={Groups} />
                  <Route path={RoutingPaths.DistrictSync} component={DistrictSync} />
                  <Route path={RoutingPaths.AdminBenchmarks} component={AdminBenchmarks} />
                  <Route path={RoutingPaths.TeacherBenchMarks} component={TeacherBenchmarks} />
                  <Route path={[
                    RoutingPaths.ScoreTaskBenchmarks,
                    `${RoutingPaths.ScoreTaskBenchmarks}/:section`,
                  ]} component={ScoreTaskBenchmark} />
                  {/* TODO: Refactor route definitions within the app be swithcing to incremental url patternss in child components */}
                </PrivateRoute>
              </BreadcrumbProvider>
            </SearchContext.Provider>
          </Switch>
        </QueryParamProvider>
      </Router>
    </LocalizationProvider>
  );
};

export default AppRouter;
