import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useTeacher from './teacher-hook';
import Loader from '../../../components/Loader/loader';
import PencilIcon from '../../../components/Icons/PencilIcon';
import StyledBoxWrapper from '../../../components/StyledCard/StyledCard.styled';
import ResourceDetailHeader from '../../Districts/District/resource-detail-header';
import ResourceDetail from '../../Districts/District/resource-detail';
import SchoolsIcon from '../../../components/Icons/SchoolsIcon';
import TeachersIcon from '../../../components/Icons/TeachersIcon';
import MessageIcon from '../../../components/Icons/MessageIcon';
import { SchoolInfo } from '../../../generated/graphql';
import { StudentsIcon } from '../../../components/Icons';
import { MY_SCHOOL, PROVIDER_TYPE } from '../../../variables/constant';
import { Entity, ImitatationUserType } from '../../../variables/types';
import AddNewEntity from '../../../components/AddNewEntity';
import DistrictsIcon from '../../../components/Icons/DistrictsIcon';
import ListWithTabs from '../../InfoTables';
import { teachersRoute } from '../../../variables/staticRoutes';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';

const Teacher = () => {
  const {
    teacher,
    loading,
    editTeacherPermission,
    isSchoolAdmin,
    handleImitationLogin,
    imitateUserPermission, teacherID,
    isDistrictAdmin, isSuperAdmin,
  } = useTeacher({});
  const breadcrumb = [
    teachersRoute,
    {
      label: `${teacher?.first_name ?? ''} ${teacher?.last_name ?? ''}`,
      route: `${teachersRoute.route}/${teacher?.id}`,
    }];
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <AppBreadcrumbs breadcrumb={loading ? [] : breadcrumb} />
      <ResourceDetailHeader
        title={`${teacher?.first_name ? teacher?.first_name : ''} ${teacher?.last_name ? teacher.last_name : ''}`}
        addNewEntity={editTeacherPermission ? (
          <AddNewEntity
            entity={Entity.Teacher}
            districtId={teacher?.district_id ?? undefined}
            stateId={teacher?.state_id ?? undefined}
            schoolId={teacher?.schools?.[0]?.id ?? undefined}
            teacherId={teacher?.id ?? undefined}
          />

        ) : undefined }
        primaryBtnRedirectionLink={isSuperAdmin ? { pathname: `/admin/create/teacher/${teacher?.id}`, state: { source: teacher?.source } } : undefined}
        PrimaryBtnIcon={isSuperAdmin ? PencilIcon : undefined}
        primaryBtnLabel={isSuperAdmin ? 'Edit' : undefined}
        secondaryBtnLabel={imitateUserPermission ? 'Log In As' : undefined}
        secondaryBtnOnClick={() => handleImitationLogin(
          teacher?.user_id!, ImitatationUserType.Teacher, teacher?.id!,
        )}
        SecondaryBtnIcon={StudentsIcon}
      />
      <Box my={2}>
        <StyledBoxWrapper width="30%">
          {teacher && teacher?.schools?.length > 0 && (
            <ResourceDetail
              label={(teacher?.schools && teacher?.schools?.length > 0 ? (
                <Box display="flex">
                  <Typography sx={{ marginRight: '4px' }}>{teacher?.job_title}</Typography>
                  {teacher?.job_title && teacher?.schools?.length > 0 ? <Typography sx={{ mr: '4px' }}>{' at '}</Typography> : ''}
                  <Box>
                    <Typography
                      key={teacher?.schools[0]?.id}
                      variant='redirectionLinks'
                      sx={{ mr: '4px' }}
                      component={Link}
                      to={isDistrictAdmin || isSchoolAdmin ? '/districts/my-district' : `/districts/${teacher?.district_id}`}
                    >
                      {teacher?.district ?? ''}
                    </Typography>
                  </Box>
                </Box>
              ) : ''
              )}
              Icon={DistrictsIcon}
            />
          )}
          <ResourceDetail label={`Teacher ID: ${teacher?.teacher_id || 'No teacher id available'}`} Icon={TeachersIcon} />
          <ResourceDetail label={teacher?.email || 'No email available'} Icon={MessageIcon} />
          {teacher && teacher?.schools?.length > 0 && (
            <ResourceDetail
              label={
                <Box display="flex">
                  <Box>
                    {teacher?.schools?.map((school: SchoolInfo | undefined | null, index: number) => (
                      <Typography
                        key={school?.id}
                        variant='redirectionLinks'
                        sx={{ mr: '4px' }}
                        component={Link}
                        to={`/schools/${isSchoolAdmin ? MY_SCHOOL : school?.id}`}
                      >
                        {school?.name ?? ''}
                        {index !== teacher?.schools?.length - 1 ? ', ' : ''}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              }
              Icon={SchoolsIcon}
            />
          )}
        </StyledBoxWrapper>
        <ListWithTabs sectionType={'teachers'} teacherID={teacherID} />
      </Box>
      <Loader open={loading} />
    </Box>
  );
};

export default React.memo(Teacher);
