import { StringParam, useQueryParam } from 'use-query-params';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ROWS_PER_PAGE } from '../../../variables/constant';
import {
  DistrictFilterFragmentFragment,
  OrderBy,
  SchoolFilterFragmentFragment,
  StateFilterFragmentFragment,
  useDistrictAdminListQuery,
  UserPermissions,
  useSchoolAdminListQuery,
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { AdminListSort, AdminType } from '../../../variables/types';
import usePermission from '../../../hooks/usePermission';
import { SearchContext } from '../../../core/searchContext';


interface RouteProp {
  type: AdminType;
}

interface Props {
  districtID?: string;
  schoolID?: string;
  adminType?: AdminType;

}

const useAdmins = ({ districtID, schoolID, adminType }: Partial<Props> = {}) => {
  const pagination = usePagination();
  const { searchTerm } = useContext(SearchContext);
  const [sort, setSort] = useQueryParam('sort', StringParam);
  const [order, setOrder] = useQueryParam('order', StringParam);
  const [selectedState, setStateValue] = useQueryParam('state', StringParam);
  const [selectedDistrict, setSelectedDistrict] = useQueryParam('district', StringParam);
  const [selectedSchool, setSelectedSchool] = useQueryParam('school', StringParam);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const { type } = useParams<RouteProp>();
  const isSchoolAdmin = type === AdminType.School || adminType === AdminType.School;
  const isDistrictAdmin = type === AdminType.District || adminType === AdminType.District;
  const { hasPermission: districtPermission } = usePermission([
    UserPermissions.ListDistricts,
    UserPermissions.ViewDistricts,
  ]);

  const section = adminType === AdminType.School ? 'school-admin' : 'district-admin';
  useEffect(() => {
    pagination.setPage(1);
  }, [rowsPerPage]);
  const { data: schoolAdminsData, loading: schoolAdminLoading } = useSchoolAdminListQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: rowsPerPage,
      page: pagination.page,
      sort,
      orderBy: order as OrderBy,
      ...(searchTerm && searchTerm.length > 2 ? { searchByName: searchTerm } : {}),
      ...(selectedState ? { stateId: selectedState } : {}),
      ...(selectedDistrict || districtID ? { districtId: districtID ?? selectedDistrict } : {}),
      ...((selectedSchool || schoolID) && isSchoolAdmin ? { schoolId: schoolID || selectedSchool } : {}),
    },
    skip: !isSchoolAdmin,
  });

  const { data: districtAdminsData, loading: districtAdminLoading } = useDistrictAdminListQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: rowsPerPage,
      page: pagination.page,
      sort,
      searchByName: searchTerm,
      orderBy: order as OrderBy,
      ...(selectedState ? { stateId: selectedState } : {}),
      ...((selectedDistrict || districtID) && isDistrictAdmin ? { districtId: districtID ?? selectedDistrict } : {}),
      ...(selectedSchool && isSchoolAdmin ? { schoolId: selectedSchool } : {}),
    },
    skip: !isDistrictAdmin,
  });

  const loading = districtAdminLoading || schoolAdminLoading;

  const onSortChange = (sortBy: AdminListSort, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const admins = isSchoolAdmin ? (schoolAdminsData?.schoolAdminList?.nodes ?? []) : (isDistrictAdmin ? (districtAdminsData?.districtAdminList?.nodes ?? []) : []);
  const totalCount = isSchoolAdmin ? (schoolAdminsData?.schoolAdminList?.totalCount ?? 0) : (isDistrictAdmin ? (districtAdminsData?.districtAdminList?.totalCount ?? 0) : 0);

  const isTableEmpty = !loading && admins?.length === 0;

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
  };

  const handleSort = (column: AdminListSort) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const onStateChange = (state: string | StateFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setStateValue(state as string);
    setSelectedDistrict(null);
    setSelectedSchool(null);
  };

  const onDistrictChange = (district: string | DistrictFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSelectedDistrict(district as string);
    setSelectedSchool(null);
  };

  const onSchoolChange = (school: string | SchoolFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSelectedSchool(school as string);
  };

  return {
    loading,
    totalCount,
    admins,
    pagination,
    onSortChange,
    sort: sort as AdminListSort,
    order: order as OrderBy,
    isTableEmpty,
    handlePageChange,
    handleSort,
    selectedState,
    onStateChange,
    selectedDistrict,
    onDistrictChange,
    selectedSchool,
    onSchoolChange,
    type: type ?? adminType,
    isSchoolAdmin,
    isDistrictAdmin,
    districtPermission,
    rowsPerPage,
    setRowsPerPage,
  };
};

export default useAdmins;
