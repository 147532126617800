import React from 'react';
import { Box } from '@mui/material';
import useSchool from './school-hook';
import Loader from '../../../components/Loader/loader';
import PencilIcon from '../../../components/Icons/PencilIcon';
import StyledBoxWrapper from '../../../components/StyledCard/StyledCard.styled';
import ResourceDetailHeader from '../../Districts/District/resource-detail-header';
import ResourceDetail from '../../Districts/District/resource-detail';
import DistrictsIcon from '../../../components/Icons/DistrictsIcon';
import SchoolsIcon from '../../../components/Icons/SchoolsIcon';
import { MY_DISTRICT, MY_SCHOOL, PROVIDER_TYPE } from '../../../variables/constant';
import { Entity } from '../../../variables/types';
import AddNewEntity from '../../../components/AddNewEntity';
import ListWithTabs from '../../InfoTables';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import { schoolsRoute } from '../../../variables/staticRoutes';




const School = () => {
  const {
    school,
    loading,
    editSchoolPermission,
    isSchoolAdmin,
    isDistrictAdmin,
    isSuperAdmin,
    schoolID,
  } = useSchool();
  const breadcrumb = [
    schoolsRoute,
    {
      label: school?.name ?? '',
      route: `${schoolsRoute.route}/${school?.id}`,
    }];
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Box>
        {(isSuperAdmin || isDistrictAdmin) && <AppBreadcrumbs breadcrumb={loading ? [] : breadcrumb} />}
      </Box>
      <ResourceDetailHeader
        title={school?.name ?? ''}
        addNewEntity={editSchoolPermission ? (
          <AddNewEntity
            entity={school?.district?.source !== PROVIDER_TYPE.FLASHLIGHT ? Entity.CleverDistrict : Entity.School}
            districtId={school?.district?.id}
            stateId={school?.district?.state?.id}
            schoolId={school?.id}
          />
        ) : undefined }
        primaryBtnRedirectionLink={`/admin/create/school/${school?.id}`}
        PrimaryBtnIcon={PencilIcon}
        primaryBtnLabel={editSchoolPermission ? 'Edit' : undefined}
      />
      <Box my={2}>
        <StyledBoxWrapper width="30%">
          <ResourceDetail
            label={school?.district?.name ?? '-'}
            link={`/districts/${isSchoolAdmin || isDistrictAdmin ? MY_DISTRICT : school?.district?.id}`}
            Icon={DistrictsIcon}
          />
          <ResourceDetail label={`School ID: ${school?.school_id ?? '-'}`} Icon={SchoolsIcon} />
        </StyledBoxWrapper>
        <ListWithTabs sectionType={'schools'} schoolID={school?.id} isMySchool={schoolID === MY_SCHOOL} isMyDistrict={!(schoolID !== MY_DISTRICT)} />
      </Box>
      <Loader open={loading} />
    </Box>
  );
};

export default React.memo(School);
