import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import {
  CreateAssignmentInput,
  StaticResource,
  StaticResourceType,
  useAssignmentQuery,
  useCreateAssignmentMutation,
  useEditAssignmentMutation, useGetAcademicSessionQuery,
  useSectionsWithGroupsQuery,
  useStaticResourcesQuery,
  useSubjectAreasQuery,
} from '../../../generated/graphql';
import { NotifierType } from '../../../variables/types';
import {
  ANCHOR_GRADE_1_2,
  ANCHOR_GRADE_3_5,
  ANCHOR_GRADE_6_8,
  ANCHOR_GRADE_9_12,
  ANCHOR_GRADE_PK_K,
  ASSIGNMENT_DOES_NOT_EXIST_ERROR,
  ASSIGNMENT_DOES_NOT_EXIST_MESSAGE,
  PAGE_SIZE,
} from '../../../variables/constant';
import usePagination from '../../../utils/usePagination';
import getErrorMessage from '../../../utils/getErrorMessage';
import { cleanDate, toUTC, toUTCEOD } from '../../../utils/dateFormat';
import { isBefore } from 'date-fns';
import { openSnackbar } from '../../../components/Notifier';
import useRedirect from '../../../hooks/useRedirect';
import gradeSort from './grade-sort';
import { useFormContext } from 'react-hook-form';

export interface StepProp {
  title: string,
}

export interface RouteParamProp {
  id?: string,
  step?: string,
}

export interface GetRouteDataProps extends RouteParamProp {
  pathname?: string,
}

export interface RouteDataProp {
  id?: string,
  step?: string,
  action?: string,
  rootPath?: string,
  currentStep?: number,
}

export type AssignmentInput = {
  id?: string,
  title: string,
  back_navigation_allowed: boolean,
  start_at: Date,
  close_at: Date,
  section_id: string,
  resource_id: string,
  is_common_prompt: boolean,
  speaking_prompt: string,
  writing_prompt: string,
  speaking_prompt_url?: string,
  speaking_prompt_file_type?: string,
  speaking_prompt_file_name?: string,
  writing_prompt_url?: string,
  writing_prompt_file_type?: string,
  writing_prompt_file_name?: string,
  resource?: StaticResource,
};

export interface RecordingTracker {
  name: string,
  isRecording: boolean,
}

export const AssignmentInputInitial: AssignmentInput = {
  id: '',
  back_navigation_allowed: true,
  close_at: new Date(),
  is_common_prompt: true,
  resource_id: '',
  section_id: '',
  speaking_prompt: '',
  speaking_prompt_file_name: '',
  speaking_prompt_file_type: '',
  speaking_prompt_url: '',
  start_at: new Date(),
  title: '',
  writing_prompt: '',
  writing_prompt_file_name: '',
  writing_prompt_file_type: '',
  writing_prompt_url: '',
};

export const StaticResourceImgSelectedInitial: StaticResource = {
  file: {
    bucket: '',
    file_type: '',
    id: '',
    key: '',
    url: '',
  },
  grade: '',
  id: '',
  image_height: 0,
  image_width: 0,
  title: '',
};

export const StaticResourceFilters: any = {
  subjectAreas: [],
  taskTypes: [],
  keywords: [],
};

export interface AssignmentContextValues {
  loading?: boolean,
  setLoading?: Function,
  routeData?: RouteDataProp,
  // Assignment Data trackers
  assignmentInput?: AssignmentInput,
  setAssignmentInput?: Function,
  staticResourceImgSelected?: StaticResource,
  setStaticResourceImgSelected?: Function,
  speakingPromptFile?: File | null,
  setSpeakingPromptFile?: Function,
  writingPromptFile?: File | null,
  setWritingPromptFile?: Function,
  // Edit Assignment Trackers
  editAssignmentLoaded?: boolean,
  setEditAssignmentLoaded?: Function,
  assignmentFetched?: any,
  setAssignmentFetched?: Function,
  // Assignment Status Trackers
  isPastAssignment?: boolean,
  setIsPastAssignment?: Function,
  isAssignmentSubmissionsSatrted?: boolean,
  setIsAssignmentSubmissionsSatrted?: Function,
  // Assignment Form Filter trackers
  selectedGrade?: string[] | undefined,
  setSelectedGrade?: Function,
  searchTerm?: string,
  setSearchTerm?: Function,
  selectedSubjects?: string[] | undefined,
  setSubjectAreas?: Function,
  selectedTaskTypes?: string[] | undefined,
  setTaskTypes: any,
  // Form Behaviour trackers
  recordingTracker?: RecordingTracker[],
  setRecordingTracker?: Function,
}

export const GetRouteData = ({ id, step, pathname }: GetRouteDataProps): RouteDataProp => {
  const action: string = pathname?.includes('/edit') ? 'edit' : 'create';
  const rootPath: string = `/assignments/${!id ? '' : (id + '/')}${action}`;
  var currentStep: number = 1;
  if (step === 'select-image') currentStep = 2;
  else if (step === 'instructions') currentStep = 3;
  else currentStep = 1;
  return {
    id,
    step,
    action,
    rootPath,
    currentStep,
  };
};

export const CreateAssignmentInit = () => {
  const { id, step } = useParams<RouteParamProp>();
  const location = useLocation();
  const routeData: RouteDataProp = GetRouteData({ id, step, pathname: location.pathname });
  const [loading, setLoading] = useState(false);
  const [assignmentInput, setAssignmentInput] = useState(AssignmentInputInitial);
  const [staticResourceImgSelected, setStaticResourceImgSelected] = useState(StaticResourceImgSelectedInitial);
  const [speakingPromptFile, setSpeakingPromptFile] = useState<File | null>(null);
  const [writingPromptFile, setWritingPromptFile] = useState<File | null>(null);
  const [editAssignmentLoaded, setEditAssignmentLoaded] = useState(false);
  const [assignmentFetched, setAssignmentFetched] = useState<any>('');
  const [isPastAssignment, setIsPastAssignment] = useState(false);
  const [isAssignmentSubmissionsSatrted, setIsAssignmentSubmissionsSatrted] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [selectedSubjects, setSubjectAreas] = useState([]);
  const [selectedTaskTypes, setTaskTypes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [recordingTracker, setRecordingTracker] = useState<RecordingTracker[]>([]);
  return {
    routeData,
    loading, setLoading,
    assignmentInput, setAssignmentInput,
    staticResourceImgSelected, setStaticResourceImgSelected,
    speakingPromptFile, setSpeakingPromptFile,
    writingPromptFile, setWritingPromptFile,
    editAssignmentLoaded, setEditAssignmentLoaded,
    assignmentFetched, setAssignmentFetched,
    isPastAssignment, setIsPastAssignment,
    isAssignmentSubmissionsSatrted, setIsAssignmentSubmissionsSatrted,
    selectedGrade, setSelectedGrade,
    searchTerm, setSearchTerm,
    recordingTracker, setRecordingTracker,
    selectedSubjects, setSubjectAreas,
    selectedTaskTypes, setTaskTypes,
  };
};

export const CreateAssignmentContext = createContext<AssignmentContextValues>({
  selectedSubjects: [],
  selectedTaskTypes: [],
  setSubjectAreas: () => { },
  setTaskTypes: () => { },
  assignmentInput: AssignmentInputInitial,
  staticResourceImgSelected: StaticResourceImgSelectedInitial,
});

export const useCreateAssignment = () => {
  const location = useLocation();
  const { navigateTo } = useRedirect();
  const {
    routeData,
    loading, setLoading,
    assignmentInput, setAssignmentInput,
    staticResourceImgSelected, setStaticResourceImgSelected,
    speakingPromptFile, setSpeakingPromptFile,
    writingPromptFile, setWritingPromptFile,
    editAssignmentLoaded, setEditAssignmentLoaded,
    assignmentFetched, setAssignmentFetched,
    isPastAssignment, setIsPastAssignment,
    isAssignmentSubmissionsSatrted, setIsAssignmentSubmissionsSatrted,
    selectedGrade, setSelectedGrade,
    searchTerm, setSearchTerm,
    recordingTracker, setRecordingTracker,
    selectedSubjects, setSubjectAreas,
    selectedTaskTypes, setTaskTypes,
  } = useContext(CreateAssignmentContext);
  const {
    reset,
  } = useFormContext();
  const gradesForImageFilter = [ANCHOR_GRADE_PK_K, ANCHOR_GRADE_1_2, ANCHOR_GRADE_3_5, ANCHOR_GRADE_6_8, ANCHOR_GRADE_9_12];
  // fetch sections list for drop-down only for step 1
  const { data, loading: sectionsLoading } = useSectionsWithGroupsQuery({
    fetchPolicy: 'network-only',
    skip: routeData?.currentStep ? routeData?.currentStep !== 1 : false,
  });
  const sections = data?.sectionsToCreatePT ?? [];
  // fetch statis Resourses only for step 2
  const { data: staticResourcesData, loading: imagesLoading } = useStaticResourcesQuery({
    fetchPolicy: 'network-only',
    variables: {
      resourceType: StaticResourceType.Regular,
      ...(selectedGrade?.length ? { grade: selectedGrade } : {}),
      ...(selectedSubjects?.length ? { subjectAreas: selectedSubjects } : {}),
      ...(selectedTaskTypes?.length ? { taskTypes: selectedTaskTypes } : {}),
      ...(searchTerm && searchTerm?.length > 2 ? { keywords: searchTerm?.trim().split(' ') } : {}),
    },
    skip: routeData?.currentStep ? routeData?.currentStep !== 2 : false,
  });

  const { data: academicSession, loading: academicSessionLoading } = useGetAcademicSessionQuery({
    fetchPolicy: 'network-only',
    skip: routeData?.currentStep !== 1,
  });
  const setGrades = gradeSort(staticResourcesData);
  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event!.target.value === '') {
      setSearchTerm?.('');
    } else {
      setSearchTerm?.(event!.target.value);
    }
  };
  const [titleState, setTitleState] = useState(assignmentInput?.title ?? '');
  const [speakingPrompt, setSpeakingPrompt] = useState(assignmentInput?.speaking_prompt ?? '');
  const [writingPrompt, setWritingPrompt] = useState(assignmentInput?.writing_prompt ?? '');

  const [createAssignment, { loading: createAssignmentInProgress }] = useCreateAssignmentMutation();
  const [editAssignment, { loading: editAssignmentInProgress }] = useEditAssignmentMutation();
  const prepAssignmentPayload = (assignment: AssignmentInput): CreateAssignmentInput => {
    const payload: CreateAssignmentInput = {
      back_navigation_allowed: assignment.back_navigation_allowed,
      title: assignment.title,
      start_at: toUTC(cleanDate(assignment.start_at) as Date),
      close_at: toUTCEOD(cleanDate(assignment.close_at) as Date),
      section_id: assignment.section_id,
      resource_id: assignment.resource_id,
      is_common_prompt: assignment.is_common_prompt,
      speaking_prompt: assignment.speaking_prompt,
      writing_prompt: assignment.writing_prompt,
    };
    if (assignment.speaking_prompt_url) {
      payload.speaking_prompt_url = assignment.speaking_prompt_url;
      payload.speaking_prompt_file_type = assignment.speaking_prompt_file_type;
      payload.speaking_prompt_file_name = assignment.speaking_prompt_file_name;
    }
    if (assignment.is_common_prompt) {
      payload.writing_prompt_url = '';
      payload.writing_prompt_file_type = '';
      payload.writing_prompt_file_name = '';
      payload.writing_prompt = '';
    } else if (assignment.writing_prompt_url) {
      payload.writing_prompt_url = assignment.writing_prompt_url;
      payload.writing_prompt_file_type = assignment.writing_prompt_file_type;
      payload.writing_prompt_file_name = assignment.writing_prompt_file_name;
    }
    return payload;
  };
  const create = async (assignment: AssignmentInput) => {
    try {
      const assignmentPayload: CreateAssignmentInput = prepAssignmentPayload(assignment);
      await createAssignment({
        variables: {
          input: { ...assignmentPayload },
        },
      });
      navigateTo('/assignments/current');
      openSnackbar({
        message: 'Assignment created successfully',
      }, NotifierType.Success);
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };
  const edit = async (assignment: AssignmentInput) => {
    try {
      const assignmentPayload: CreateAssignmentInput = prepAssignmentPayload(assignment);
      await editAssignment({
        variables: {
          input: { ...assignmentPayload, 'id': assignment.id! },
        },
      });
      navigateTo('/assignments/current');
      openSnackbar({
        message: 'Assignment Edited successfully',
      }, NotifierType.Success);
    } catch (err) {
      if (getErrorMessage(err) === ASSIGNMENT_DOES_NOT_EXIST_ERROR) {
        openSnackbar({
          message: ASSIGNMENT_DOES_NOT_EXIST_MESSAGE,
        }, NotifierType.Error);
      } else {
        openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
      }
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateAssignmentInput = (assgnData: Object) => {
    setAssignmentInput?.({
      ...assignmentInput,
      ...assgnData,
    });
  };
  const rootPath = routeData?.rootPath;
  const backToAssignments = () => {
    navigateTo('/assignments/');
  };
  const handlePrev = (dataForm?: any) => {
    if (dataForm) updateAssignmentInput?.({
      ...dataForm,
      title: titleState,
      speaking_prompt: speakingPrompt,
      writing_prompt: writingPrompt,
    });
    if (matchPath(location.pathname, `${rootPath}/details`)) {
      backToAssignments?.();
    } else if (matchPath(location.pathname, `${rootPath}/select-image`)) {
      navigateTo(`${rootPath}/details`);
    } else if (matchPath(location.pathname, `${rootPath}/instructions`)) {
      navigateTo(`${rootPath}/select-image`);
    }
  };
  const handleNext = () => {
    if (matchPath(location.pathname, `${rootPath}/details`)) {
      navigateTo(`${rootPath}/select-image`);
    } else if (matchPath(location.pathname, `${rootPath}/select-image`)) {
      navigateTo(`${rootPath}/instructions`);
    } else if (matchPath(location.pathname, `${rootPath}/instructions`)) {
      if (assignmentInput) {
        create?.(assignmentInput);
      }
    }
  };
  const submitStep = async (formData: Object) => {
    await updateAssignmentInput?.(formData);
    handleNext();
  };

  const pagination = usePagination();
  const { data: assignmentLoaded, loading: assignmentLoading } = useAssignmentQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: PAGE_SIZE,
      page: pagination.page,
      id: routeData?.id!,
    },
    skip: editAssignmentLoaded || !routeData?.id,
  });

  const assignmentDefaultValues = useMemo(() => {
    if (!assignmentLoading) {
      return {
        id: assignmentLoaded?.assignment?.id,
        title: assignmentLoaded?.assignment?.title,
        back_navigation_allowed: assignmentLoaded?.assignment?.back_navigation_allowed,
        start_at: assignmentLoaded?.assignment?.start_at,
        close_at: assignmentLoaded?.assignment?.close_at,
        section_id: assignmentLoaded?.assignment?.section?.id,
        resource_id: assignmentLoaded?.assignment?.resource_id,
        is_common_prompt: assignmentLoaded?.assignment?.is_common_prompt,
        speaking_prompt: assignmentLoaded?.assignment?.speaking_prompt,
        writing_prompt: assignmentLoaded?.assignment?.writing_prompt,
        speaking_prompt_url: assignmentLoaded?.assignment?.speaking_prompt_file?.url,
        speaking_prompt_file_type: assignmentLoaded?.assignment?.speaking_prompt_file?.file_type,
        speaking_prompt_file_name: assignmentLoaded?.assignment?.speaking_prompt_file?.file_name,
        writing_prompt_url: assignmentLoaded?.assignment?.writing_prompt_file?.url,
        writing_prompt_file_type: assignmentLoaded?.assignment?.writing_prompt_file?.file_type,
        writing_prompt_file_name: assignmentLoaded?.assignment?.writing_prompt_file?.file_name,
        static_resource: assignmentLoaded?.assignment?.resource,
      };
    }
    return { ...assignmentInput };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentLoaded, assignmentLoading]);

  useEffect(() => {
    if (editAssignmentLoaded !== undefined && sectionsLoading !== undefined) {
      if (assignmentLoaded && !editAssignmentLoaded && !sectionsLoading) {
        updateAssignmentInput({
          ...assignmentDefaultValues,
        });
        setSpeakingPromptFile?.(null);
        setWritingPromptFile?.(null);
        setEditAssignmentLoaded?.(true);
      }
      if (assignmentLoaded) {
        setIsPastAssignment?.(isBefore(cleanDate(assignmentDefaultValues?.close_at, true), new Date()));
        setIsAssignmentSubmissionsSatrted?.(assignmentLoaded?.assignment?.is_submissions_started);
        setAssignmentFetched?.(assignmentLoaded);
        setStaticResourceImgSelected?.(assignmentLoaded?.assignment?.resource);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentDefaultValues, sectionsLoading, imagesLoading, assignmentLoaded]);

  useEffect(() => {
    setLoading?.(sectionsLoading || imagesLoading || assignmentLoading || createAssignmentInProgress || editAssignmentInProgress || academicSessionLoading);
  }, [sectionsLoading, imagesLoading, assignmentLoading, createAssignmentInProgress, editAssignmentInProgress, academicSessionLoading]);

  useEffect(() => {
    reset(assignmentInput);
  }, [assignmentInput]);

  const { data: subjectAreas, loading: subjectsLoading } = useSubjectAreasQuery({
    fetchPolicy: 'network-only',
  });

  const resetFilters = () => {
    setSelectedGrade?.([]);
    setTaskTypes([]);
    setSearchTerm?.('');
    setSubjectAreas?.();
  };

  return {
    routeData,
    loading,
    assignmentInput, setAssignmentInput,
    staticResourceImgSelected, setStaticResourceImgSelected,
    speakingPromptFile, setSpeakingPromptFile,
    writingPromptFile, setWritingPromptFile,
    editAssignmentLoaded, setEditAssignmentLoaded,
    assignmentFetched, setAssignmentFetched,
    isPastAssignment, setIsPastAssignment,
    isAssignmentSubmissionsSatrted, setIsAssignmentSubmissionsSatrted,
    selectedGrade, setSelectedGrade,
    searchTerm, setSearchTerm,
    recordingTracker, setRecordingTracker,
    gradesForImageFilter,
    sections,
    setGrades,
    onSearch,
    createAssignment: create,
    editAssignment: edit,
    updateAssignmentInput,
    handlePrev,
    handleNext,
    submitStep,
    backToAssignments,
    subjectAreas: subjectAreas?.subjectArea, subjectsLoading,
    setSubjectAreas, selectedSubjects,
    selectedTaskTypes, setTaskTypes, resetFilters,
    titleState, setTitleState,
    speakingPrompt, setSpeakingPrompt,
    writingPrompt, setWritingPrompt,
    academicSessionEndDate: academicSession?.getAcademicSession.session_end_date,
  };
};

export default useCreateAssignment;
