import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  useGetDistrictByIdQuery,
} from '../../../generated/graphql';
import { PUBLIC } from '../../../variables/constant';
import useLocalStorageState from '../../../hooks/useLocalStorageState';
import useUserInfo from '../../../utils/useUserInfo';
import usePrevious from '../../../utils/usePrevious';

const useLogin = () => {
  const { username, password, districtId }: any = useParams();
  const prevEmail = usePrevious(username);
  const prevPassword = usePrevious(password);
  const [stateLoading, setLoading] = useState(false);
  const [isLoadingUserInfo] = useUserInfo();
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const history = useHistory();
  const { setValue, register, handleSubmit, errors, watch } = useForm();
  const [district, setDistrict] = useLocalStorageState('school-district', {});
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleRedirection = () => {
    history.push('/login');
    setDistrict({});
  };

  const handleUsername = (event: any) => {
    const content = event.target.value.toString().trim();
    if (content !== ''){
      setValue('username', content.toLowerCase());
    }
  };

  const { data, loading } = useGetDistrictByIdQuery({
    context: { clientName: PUBLIC },
    variables: {
      id: districtId,
    },
  });

  const districtData = data?.getDistrictById ?? null;

  useEffect(() => {
    if (!loading) {
      if (!data?.getDistrictById?.id) {
        history.push('/login');
      } else {
        setDistrict(data?.getDistrictById);
      }
    }
  }, [loading, data, history, setDistrict]);

  return {
    loading: loading || isLoadingUserInfo || stateLoading,
    username,
    password,
    prevEmail,
    prevPassword,
    districtId,
    newPasswordRequired,
    setNewPasswordRequired,
    cognitoUser,
    setCognitoUser,
    handleRedirection,
    setLoading,
    handleSubmit,
    register,
    errors,
    watch,
    districtData,
    setDistrict,
    district,
    handleClickShowPassword,
    handleMouseDownPassword,
    showPassword,
    handleUsername,
  };
};

export default useLogin;
