import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { AdminType, TabsHeadings } from '../../variables/types';
import useTabs from './tabs-hook';
import { StyledAllTab, StyledTabs } from './tabs.styled';
import SchoolList from './SchoolsList';
import ClassesList from './ClassesList';
import StudentsList from './StudentsList';
import TeachersList from './TeachersList';
import SchoolAdminsList from './SchoolAdminsList';
import SyncsList from './SyncsList';
import SectionGroupsList from './SectionGroups/GroupsList';

interface Props {
  sectionType: string;
  isExternalSource?: boolean;
  districtID?: string;
  schoolID?: string;
  teacherID?: string;
  classID?: string;
  studentID?: string;
  adminID?: string;
  isMyDistrict?: boolean;
  isMySchool?: boolean;
}

interface ComponentEntity {
  component: ReactNode,
  section: TabsHeadings,
}

const ListWithTabs = ({ sectionType, districtID, classID, schoolID, studentID, teacherID, adminID, isExternalSource, isMyDistrict, isMySchool }: Props) => {
  const id = adminID || districtID || schoolID || classID || studentID || teacherID;
  const { tab, handleTabChange, allTabs, selectedTabType } = useTabs({ sectionType, id, districtID, schoolID, isExternalSource, isMyDistrict, isMySchool });
  const components: ComponentEntity[] = [
    {
      component: (<SchoolList districtID={districtID} />),
      section: TabsHeadings.Schools,
    },
    {
      component: (<ClassesList districtID={districtID} schoolID={schoolID} teacherID={teacherID} studentID={studentID} />),
      section: TabsHeadings.Classes,
    },
    {
      component: (<SchoolAdminsList districtID={districtID} schoolID={schoolID} />),
      section: TabsHeadings.SchoolAdmins,
    },
    {
      component: (<SchoolAdminsList districtID={districtID} schoolID={schoolID} adminType={AdminType.District} />),
      section: TabsHeadings.DistrictAdmins,
    },
    {
      component: (<TeachersList districtID={districtID} schoolID={schoolID} /> ),
      section: TabsHeadings.Teachers,
    },
    {
      component: (<StudentsList districtID={districtID} schoolID={schoolID} classID={classID} teacherID={teacherID} />),
      section: TabsHeadings.Students,
    },
    {
      component: (<SectionGroupsList sectionID={classID} />),
      section: TabsHeadings.Groups,
    },
    {
      component: (<SyncsList districtID={districtID} />),
      section: TabsHeadings.Syncs,
    },
  ];
  return (
    <Box mt={2}>
      <StyledTabs
        value={tab}
        onChange={handleTabChange}
        textColor="primary"
        scrollButtons={false}
      >
        { allTabs.map((tabItem) => (
          <StyledAllTab
            key={tabItem.section}
            classes={{ root: 'all' }}
            value={tabItem.tab}
            disableRipple
            label={(
              <Typography>
                <Typography
                  component="span"
                  className="tag"
                  sx={ tabItem.count && tabItem.count?.toString()?.length > 3 ? { minWidth: '76px' } : {}}
                >
                  {tabItem.count ?? 0}
                </Typography>
                <Typography component="span">
                  {tabItem.label}
                </Typography>
              </Typography>
            )}
          />
        ))}
      </StyledTabs>
      <Box display="flex" >
        {components.find(comp => comp.section === selectedTabType)?.component}
      </Box>
    </Box>
  );
};

export default ListWithTabs;