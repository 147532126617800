import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { palette } from '../../../theme/palette';
import useRole from '../../../hooks/useRole';

interface LinkProps {
  pathname: string,
  state: any,
}
interface Props {
  title: string,
  primaryBtnLabel?: string,
  PrimaryBtnIcon?: any,
  primaryBtnRedirectionLink?: string | LinkProps
  secondaryBtnLabel?: string,
  SecondaryBtnIcon?: any,
  secondaryBtnRedirectionLink?: string,
  secondaryBtnOnClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  addNewEntity?: React.ReactNode,
  syncNowRedirectionLink?: string,
  syncNowEnabled?: Boolean | null,
  syncNowShow?: Boolean | null,
  handleSecondryBtnRedirectionClick?: Function,
}

const styles = {
  icon: {
    marginRight: '8px',
    fill: palette.customBlue.primaryBlue,
    '& path': {
      fill: palette.customBlue.primaryBlue,
    },
  },
  button: {
    fontWeight: 800,
    width: '100%',
    minWidth: '220px',
  },
};


const ResourceDetailHeader = ({
  title,
  primaryBtnLabel,
  PrimaryBtnIcon,
  primaryBtnRedirectionLink,
  secondaryBtnLabel,
  SecondaryBtnIcon,
  secondaryBtnRedirectionLink,
  secondaryBtnOnClick,
  addNewEntity,
  syncNowRedirectionLink,
  syncNowEnabled = false,
  syncNowShow = false,
  handleSecondryBtnRedirectionClick = () => { },
}: Props) => {

  const getActionElementsCount = () => {
    const actionElements = [primaryBtnLabel, secondaryBtnLabel, addNewEntity, syncNowRedirectionLink];
    return actionElements.filter(Boolean).length;
  };

  const { isSuperAdmin } = useRole();

  return (
    <Box
      display="grid"
      gridTemplateColumns={`1fr repeat(${getActionElementsCount() || 0}, 220px)`}
      alignItems="center"
    >
      <Box sx={{ display: 'flex', width: '500px', maxWidth: '100%' }}>
        <Typography variant="pageTitle" sx={{ wordBreak: 'break-word' }}>
          {title}
        </Typography>
      </Box>
      {syncNowRedirectionLink && isSuperAdmin && syncNowShow && (
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          sx={{ ...styles.button, py: 1.5 }}
          to={syncNowRedirectionLink}
          disabled={Boolean(syncNowEnabled)}
        >
         Sync Now
        </Button>
      )}
      {secondaryBtnLabel && (secondaryBtnOnClick ? (
        <Button
          variant="outlined"
          color="primary"
          sx={{ ...styles.button }}
          onClick={secondaryBtnOnClick || (() => {})}
          startIcon={SecondaryBtnIcon ? <SecondaryBtnIcon  sx={{ ...styles.icon }} /> : undefined}
        >
          {secondaryBtnLabel}
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          sx={{ ...styles.button }}
          to={secondaryBtnRedirectionLink ?? ''}
          startIcon={SecondaryBtnIcon ? <SecondaryBtnIcon sx={{ ...styles.icon }} /> : undefined}
          onClick={() => handleSecondryBtnRedirectionClick()} 
        >
          {secondaryBtnLabel}
        </Button>
      ))}
      {addNewEntity && <>{addNewEntity}</> }
      {primaryBtnLabel && PrimaryBtnIcon && primaryBtnRedirectionLink && (
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          sx={{  py: 1.75 }}
          to={primaryBtnRedirectionLink}
          startIcon={<PrimaryBtnIcon sx={{ ...styles.icon }} />}
        >
          {primaryBtnLabel}
        </Button>
      )}
    </Box>
  );
};

export default ResourceDetailHeader;
